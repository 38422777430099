import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { UserContext } from './context';
import './assets/styles/details.styles.css'
import './jobStatus.css'

const { hostname } = window.location;
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

export default function JobStatus() {
    const { accessToken } = useContext(UserContext);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [jobIds, setJobIds] = useState([]);

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const [sortField, setSortField] = useState(queryParams.get('sort') || '');
    const [sortOrder, setSortOrder] = useState(queryParams.get('order') || 'asc');
    const [stageFilter, setStageFilter] = useState(queryParams.get('stage') || '');
    const [typeFilter, setTypeFilter] = useState(queryParams.get('type') || '');
    const [contactFilter, setContactFilter] = useState(queryParams.get('contact') || '');
    const [dateStartFilter, setDateStartFilter] = useState(queryParams.get('dateStart') || '');
    const [dateEndFilter, setDateEndFilter] = useState(queryParams.get('dateEnd') || '');
    const [skuFilter, setSkuFilter] = useState(queryParams.get('sku') || '');
    const [partReceivedFilter, setPartReceivedFilter] = useState(queryParams.get('part') || '');

    const [filterChanged, setFilterChanged] = useState(false);
    const [resetFilter, setResetFilter] = useState(false);

    const [currentPage, setCurrentPage] = useState(queryParams.get('page') || 1);
    const pageSize = 20;
    const [totalCount, setTotalCount] = useState(0);
    const totalPages = Math.ceil(totalCount / pageSize);
    const [searchPage, setSearchPage] = useState('');

    const back = () => {
        navigate('/dashboard');
    };

    const fetchDataRef = useRef();

    const fetchData = useCallback(async () => {
        setLoading(true);
        const params = new URLSearchParams({
            page: currentPage,
            limit: pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
            stageFilter: stageFilter,
            typeFilter: typeFilter,
            contactFilter: contactFilter,
            dateStartFilter: dateStartFilter,
            dateEndFilter: dateEndFilter,
            skuFilter: skuFilter,
            partReceivedFilter: partReceivedFilter
        });

        const response = await fetch(`${apiBaseUrl}/api/protected/jobStatus?${params}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        const data = await response.json();
        setJobs(data.data);
        setTotalCount(data.totalCount);
        setJobIds(data.jobIds);
        setFilterChanged(false);
        setLoading(false);
    }, [currentPage, pageSize, sortField, sortOrder, stageFilter, typeFilter, contactFilter, dateStartFilter, dateEndFilter, skuFilter, partReceivedFilter, accessToken]);

    fetchDataRef.current = fetchData;

    useEffect(() => {
        fetchDataRef.current();
        setResetFilter(false);
    }, [currentPage, pageSize, sortField, sortOrder, resetFilter]);

    const applyFilters = () => {
        fetchDataRef.current();
        setFilterChanged(false);
    };

    const downloadCSV = async () => {
        try {
            const params = new URLSearchParams({
                jobIds: JSON.stringify(jobIds),
            });

            const response = await fetch(`${apiBaseUrl}/api/protected/jobStatusDownload?${params}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'jobs-data.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error("Failed to download CSV file.");
            }
        } catch (error) {
            console.error("Error downloading CSV file", error);
        }
    };

    const [stagesOfRepair, setStagesOfRepair] = useState([
        'Awaiting Delivery',
        'Received and Awaiting Assessment',
        'Waiting for Approval (parts ordering)',
        'Waiting for Approval (repair charges)',
        'Waiting for Approval (charges and parts)',
        'Awaiting Delivery to Outlet (job cancelled)',
        'Waiting for Accounting',
        'Waiting for Accounting and Spare Parts',
        'Waiting for Spare Parts',
        'Repair Process',
        '24 Hour Test 1',
        'Quality Check 1',
        '24 Hour Test 2',
        'Quality Check 2',
        'Awaiting Delivery to Outlet',
        'Outlet 24 Hour Test',
        'Outlet Quality Check',
        'Job Failed',
        'Ready for Collection',
        'Ready for Collection (job cancelled)',
        'Job Completed',
        'Job Cancelled'
    ]);

    const handleStageFilter = (value) => {
        setStageFilter('');
        setTypeFilter(value);
        if (value) {
            if (value === 'Delivery') {
                setStagesOfRepair(['Awaiting Delivery', 'Awaiting Delivery to Outlet', 'Awaiting Delivery to Outlet (job cancelled)']);
            }
            if (value === 'Repair') {
                setStagesOfRepair(['Received and Awaiting Assessment', 'Repair Process', '24 Hour Test 1', 'Quality Check 1', '24 Hour Test 2', 'Quality Check 2']);
            }
            if (value === 'Customer Service') {
                setStagesOfRepair(['Waiting for Approval (parts ordering)', 'Waiting for Approval (repair charges)', 'Waiting for Approval (charges and parts)']);
            }
            if (value === 'Accounting') {
                setStagesOfRepair(['Waiting for Accounting', 'Waiting for Accounting and Spare Parts']);
            }
            if (value === 'Waiting for Spare Parts') {
                setStagesOfRepair(['Waiting for Accounting and Spare Parts', 'Waiting for Spare Parts']);
            }
            if (value === 'Outlet') {
                setStagesOfRepair(['Outlet 24 Hour Test', 'Outlet Quality Check', 'Ready for Collection', 'Ready for Collection (job cancelled)']);
            }
            if (value === 'Inactive') {
                setStagesOfRepair(['Job Completed', 'Job Cancelled', 'Job Failed']);
            }
        }
    };

    const handleStageFilterClear = () => {
        setTypeFilter('');
        setStagesOfRepair([
            'Awaiting Delivery',
            'Received and Awaiting Assessment',
            'Waiting for Approval (parts ordering)',
            'Waiting for Approval (repair charges)',
            'Waiting for Approval (charges and parts)',
            'Awaiting Delivery to Outlet (job cancelled)',
            'Waiting for Accounting',
            'Waiting for Accounting and Spare Parts',
            'Waiting for Spare Parts',
            'Repair Process',
            '24 Hour Test 1',
            'Quality Check 1',
            '24 Hour Test 2',
            'Quality Check 2',
            'Awaiting Delivery to Outlet',
            'Outlet 24 Hour Test',
            'Outlet Quality Check',
            'Job Failed',
            'Ready for Collection',
            'Ready for Collection (job cancelled)',
            'Job Completed',
            'Job Cancelled'
        ]);
    }

    const sortData = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        };
    };

    const renderPageButtons = () => {
        const pageButtons = [];

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageButtons.push(
                    <button
                        key={i}
                        onClick={() => handlePageChange(i)}
                        disabled={currentPage === i}
                        style={{
                            backgroundColor: currentPage === i ? '#333' : 'black',
                            border: currentPage === i ? '1.5px solid #333' : 'black'
                        }}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            if (currentPage <= 3) {
                for (let i = 1; i <= 5; i++) {
                    pageButtons.push(
                        <button
                            key={i}
                            onClick={() => handlePageChange(i)}
                            disabled={currentPage === i}
                            style={{
                                backgroundColor: currentPage === i ? '#333' : 'black',
                                border: currentPage === i ? '1.5px solid #333' : 'black'
                            }}
                        >
                            {i}
                        </button>
                    );
                }
                pageButtons.push(<span key="ellipsis1">...</span>);
                pageButtons.push(
                    <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
                        {totalPages}
                    </button>
                );
            } else if (currentPage >= totalPages - 2) {
                pageButtons.push(
                    <button key={1} onClick={() => handlePageChange(1)}>
                        1
                    </button>
                );
                pageButtons.push(<span key="ellipsis2">...</span>);
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    pageButtons.push(
                        <button
                            key={i}
                            onClick={() => handlePageChange(i)}
                            disabled={currentPage === i}
                            style={{
                                backgroundColor: currentPage === i ? '#333' : 'black',
                                border: currentPage === i ? '1.5px solid #333' : 'black'
                            }}
                        >
                            {i}
                        </button>
                    );
                }
            } else {
                pageButtons.push(
                    <button key={1} onClick={() => handlePageChange(1)}>
                        1
                    </button>
                );
                pageButtons.push(<span key="ellipsis3">...</span>);
                for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                    pageButtons.push(
                        <button
                            key={i}
                            onClick={() => handlePageChange(i)}
                            disabled={currentPage === i}
                            style={{
                                backgroundColor: currentPage === i ? '#333' : 'black',
                                border: currentPage === i ? '1.5px solid #333' : 'black'
                            }}
                        >
                            {i}
                        </button>
                    );
                }
                pageButtons.push(<span key="ellipsis4">...</span>);
                pageButtons.push(
                    <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
                        {totalPages}
                    </button>
                );
            }
        }

        return pageButtons;
    };

    const handleSearchPageChange = (e) => {
        setSearchPage(e.target.value);
    };

    const handleGoToPage = () => {
        const page = parseInt(searchPage);
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            setSearchPage(page);
        }
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <header className='header'>
                <h1>All Jobs</h1>
            </header>
            <div className='back spaceBetween'>
                <button type="button" onClick={back}>Back</button>
                <button type="button" onClick={downloadCSV}>Download Data</button>
            </div>
            <div>
                <div className='filterIsland'>
                    <h3 style={{ marginLeft: '1.5rem', paddingTop: '1rem', marginBottom: '0' }}>Filter</h3>
                    <div className='filters'>
                        <div className='filter-column filter'>

                            <div className='filter-group-3'>
                                <label>Stage Type:</label><br />
                                <select value={typeFilter} onChange={(e) => { handleStageFilter(e.target.value); setFilterChanged(true); }}>
                                    <option value="" disabled hidden>Select stage type</option>
                                    <option value="Delivery">Delivery</option>
                                    <option value="Repair">Repair</option>
                                    <option value="Customer Service">Customer Service</option>
                                    <option value="Accounting">Accounting</option>
                                    <option value="Waiting for Spare Parts">Waiting for Spare Parts</option>
                                    <option value="Outlet">Outlet</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                                <button style={{ marginLeft: '1rem' }} onClick={() => { handleStageFilterClear(); setFilterChanged(true); }}>X</button>
                            </div>

                            <div className='filter-group-3'>
                                <label>Stage:</label><br />
                                <select value={stageFilter} onChange={(e) => { setStageFilter(e.target.value); setFilterChanged(true); }}>
                                    <option value="" disabled hidden>Select stage</option>
                                    {stagesOfRepair && stagesOfRepair.map((stage) => (
                                        <option key={stage} value={stage}>{stage}</option>
                                    ))}
                                </select>
                                <button style={{ marginLeft: '1rem' }} onClick={() => { setStageFilter(''); setFilterChanged(true); }}>X</button>
                            </div>

                            <div className='filter-group-3'>
                                <label>Contact:</label><br />
                                <input className='phone' type="number" value={contactFilter} onChange={(e) => { setContactFilter(e.target.value); setFilterChanged(true); }} />
                                <button style={{ marginLeft: '1rem' }} onClick={() => { setContactFilter(''); setFilterChanged(true); }}>X</button>
                            </div>

                        </div>
                        <div className='filter-column filter'>

                            <div className='filter-group-4 dateFlexAlign'>
                                <label>Date:</label><br style={{ flexBasis: '100%', height: 0 }} />
                                <div className='date'>
                                    <input type="date" className='dateInput' value={dateStartFilter} onChange={(e) => { setDateStartFilter(e.target.value); setFilterChanged(true); }} />
                                    <p style={{ paddingLeft: '3px', paddingRight: '3px' }}>-</p>
                                    <input type="date" className='dateInput' value={dateEndFilter} onChange={(e) => { setDateEndFilter(e.target.value); setFilterChanged(true); }} />
                                    <button style={{ marginLeft: '1rem' }} onClick={() => { setDateStartFilter(''); setDateEndFilter(''); setFilterChanged(true); }}>X</button>
                                </div>
                            </div>

                            <div className='filter-group-4'>
                                <label>SKU:</label><br />
                                <input type="text" value={skuFilter} onChange={(e) => { setSkuFilter(e.target.value); setFilterChanged(true); }} maxLength={100} />
                                <button style={{ marginLeft: '1rem' }} onClick={() => { setSkuFilter(''); setFilterChanged(true);; }}>X</button>
                            </div>

                            <div className='filter-group-4'>
                                <label>Part:</label><br />
                                <select value={partReceivedFilter} onChange={(e) => { setPartReceivedFilter(e.target.value); setFilterChanged(true); }}>
                                    <option value="" disabled hidden>Select part</option>
                                    <option value="Base">Base</option>
                                    <option value="Glass Chamber with Tip">Glass Chamber with Tip</option>
                                    <option value="Full Set">Full Set</option>
                                    <option value="Others">Others</option>
                                </select>
                                <button style={{ marginLeft: '1rem' }} onClick={() => { setPartReceivedFilter(''); setFilterChanged(true); }}>X</button>
                            </div>

                        </div>

                        <div className='clear-all-container'>
                            {filterChanged && (
                                <button className='mediaButton' style={{ marginRight: '0.5rem' }} onClick={applyFilters}>Apply Filters</button>
                            )}
                            {!filterChanged && (
                                <button className='mediaButton' style={{ marginRight: '0.5rem', backgroundColor: 'gray', border: 'gray', cursor: 'default' }} disabled>Apply Filters</button>
                            )}
                            <button className='mediaButton' onClick={() => {
                                setStageFilter('');
                                handleStageFilterClear();
                                setContactFilter('');
                                setDateStartFilter('');
                                setDateEndFilter('');
                                setSkuFilter('');
                                setPartReceivedFilter('');
                                setFilterChanged(true);
                                setResetFilter(true);
                            }}>
                                Clear Filters
                            </button>
                        </div>
                    </div>
                </div>
                <div className='table-wrapper mobileScroll'>
                    <table className='fitTable'>
                        <thead className='head'>
                            <tr>
                                <th className='sort' onClick={() => sortData('jobid')}>Job Id {sortField === 'jobid' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('sku')}>SKU {sortField === 'sku' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th>Part Received</th>
                                <th>POS</th>
                                <th className='sort' onClick={() => sortData('warranty')}>Warranty {sortField === 'warranty' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('timestamp')}>Created At {sortField === 'timestamp' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('outletreceived')}>Outlet Received {sortField === 'outletreceived' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('outletcollection')}>Collection Outlet {sortField === 'outletcollection' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('customername')}>Customer Name {sortField === 'customername' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th>Customer Contact</th>
                                <th className='sort' onClick={() => sortData('vip')}>VIP {sortField === 'vip' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('stageofrepair')}>Job Status {sortField === 'stageofrepair' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('arrivaltimestamp')}>Arrived at HQ {sortField === 'arrivaltimestamp' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('completetimestamp')}>Completed At {sortField === 'completetimestamp' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('rootjobid')}>Root Job Id {sortField === 'rootjobid' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobs.map((job) => (
                                <tr key={job.jobid}>
                                    <td>
                                        <Link to={`/jobStatus/${job.jobid}?stage=${stageFilter}&type=${typeFilter}&contact=${contactFilter}&dateStart=${dateStartFilter}&dateEnd=${dateEndFilter}&sku=${skuFilter}&part=${partReceivedFilter}&sort=${sortField}&order=${sortOrder}&page=${currentPage}`}>
                                            {job.jobid}
                                        </Link>
                                    </td>
                                    <td>{job.sku}</td>
                                    <td>{job.partreceived}</td>
                                    <td>{job.pos}</td>
                                    <td>{job.warranty === 1 ? 'True' : 'False'}</td>
                                    <td>{new Date(job.timestamp).toLocaleString('en-gb')}</td>
                                    <td>{job.outletreceived}</td>
                                    <td>{job.outletcollection}</td>
                                    <td>{job.customername}</td>
                                    <td>{job.customercontact}</td>
                                    <td>{job.vip === 1 ? 'True' : 'False'}</td>
                                    <td>{job.stageofrepair}</td>
                                    <td>{job.arrivaltimestamp ? new Date(job.arrivaltimestamp).toLocaleString('en-gb') : 'NA'}</td>
                                    <td>{job.completetimestamp ? new Date(job.completetimestamp).toLocaleString('en-gb') : 'NA'}</td>
                                    <td>{job.rootjobid}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='pagination'>
                    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            style={{ marginRight: '1rem' }}
                        >
                            &lt;
                        </button>
                        {renderPageButtons()}
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            style={{ marginLeft: '1rem' }}
                        >
                            &gt;
                        </button>
                    </div>
                    <div style={{ marginBottom: '1rem' }}>
                        <label style={{ marginRight: '1rem' }}>Go to page:</label>
                        <input
                            type="number"
                            min={1}
                            max={totalPages}
                            value={searchPage}
                            onChange={handleSearchPageChange}
                        />
                        <button style={{ marginLeft: '1rem' }} onClick={handleGoToPage}>Go</button>
                    </div>
                    {/* <div>
                        <input
                            type="number"
                            min={1}
                            max={100}
                            value={pageSize}
                            onChange={(e) => setPageSize(e.target.value)}
                        />
                    </div> */}
                </div>
            </div>
        </div >
    );
};