import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { useParams, useNavigate, Navigate, useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { UserContext } from './context';
import './assets/styles/repair.styles.css'

const { hostname } = window.location;
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

Modal.setAppElement('#root');

const OutletDetails = () => {
    const { user, accessToken, accessiblePages } = useContext(UserContext);
    const navigate = useNavigate();

    let { jobId } = useParams();
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [warranty, setWarranty] = useState('');

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const sortField = queryParams.get('sort') || '';
    const sortOrder = queryParams.get('order') || 'asc';
    const page = queryParams.get('page') || 1;

    const [filters,] = useState({
        sort: sortField,
        order: sortOrder,
        page: page
    });

    const [stage, setStage] = useState('');
    const [imageCheck, setImageCheck] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);

    const [shouldFetch, setShouldFetch] = useState(false);

    const [testStart, setTestStart] = useState(null);
    const [testFinish, setTestFinish] = useState(null);
    const [showTestModal, setShowTestModal] = useState(false);
    const [showTestFailModal, setShowTestFailModal] = useState(false);
    const [showCheckFailModal, setShowCheckFailModal] = useState(false);

    const [QCComplete, setQCComplete] = useState(false);

    const back = () => {
        const { sort, order, page } = filters;
        navigate(`/outlet?sort=${sort}&order=${order}&page=${page}`);
    };

    const checks = useMemo(() => [
        'Visible Mist?',
        'Visible LED Lights (7 colours)?',
        'Switch (Low, Med, High) working fine?',
        'Switch (On, Off) working fine?',
        'Intermittent Rest (2 M/ins, 1 Min) functioning?',
        'Slight Vibration?',
        'No unusual sound / noisy (aside from normal vibration sound)?',
        'Glass chamber inner tube not broken / chipped?',
        'No residual oil inside the diffuser and on the glass (To clean with cleaning solution and air dry)?',
        'No water mark inside the diffuser and on the glass (To clean with cleaning solution and air dry)?',
        'Visual check rubber in good condition and right position?',
        'Adaptor functioning?'
    ], []);

    const fetchData = useCallback(async (render) => {
        if (render) {
            setLoading(true);
        }
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/outlet/${jobId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const imageUrls = data.images.map(imageData => {
                const uint8Array = new Uint8Array(imageData.imagedata.data);
                const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                const imageUrl = URL.createObjectURL(blob);
                return imageUrl;
            });

            setJobs({ ...data, imageUrls });
        } catch (error) {
            console.error("Failed to fetch job details:", error);
        } finally {
            setLoading(false);
        };
    }, [jobId, accessToken]);

    useEffect(() => {
        if (accessiblePages.includes('outlet')) {
            fetchData(true);
        };
    }, [jobId, fetchData, accessiblePages]);

    const isQualityCheckComplete = useCallback(() => {
        const qualityCheckDetails = jobs.checkDetails.filter(check => check.stagetype === 'Outlet Quality Check');

        const allChecksPresent = checks.every(checkType =>
            qualityCheckDetails.some(check => check.checktype === checkType)
        );

        if (!allChecksPresent) return false;

        return qualityCheckDetails.every(check => check.endtime || !check.applicable);
    }, [jobs, checks]);

    useEffect(() => {
        if (jobs.warranty) {
            setWarranty('True');
        } else {
            setWarranty('False');
        };
        setStage(jobs.stageofrepair);
        if (jobs.checkDetails && jobs.stageofrepair === 'Outlet Quality Check') {
            const qualityCheckIsComplete = isQualityCheckComplete();
            setQCComplete(qualityCheckIsComplete);
        };
        if (jobs.test) {
            setTestStart(jobs.test.startTime || '');
            setTestFinish(jobs.test.finishTime || '');
        };
        if (jobs.imageUrls) {
            setImageCheck(jobs.imageUrls);
        };
    }, [jobs, isQualityCheckComplete]);

    useEffect(() => {
        if (shouldFetch && (accessiblePages.includes('outlet'))) {
            fetchData(false);
            setShouldFetch(false);
        };
    }, [shouldFetch, fetchData, accessiblePages]);

    if (!accessiblePages.includes('outlet')) {
        return <Navigate to="/dashboard" />;
    };

    const handleImageNameClick = (image) => {
        setSelectedImage(image);
        setShowImageModal(true);
    };

    const customStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const displayChecks = checks.map(checkType => {
        const checkDetails = jobs.checkDetails ? jobs.checkDetails.find(check => check.checktype === checkType) : null;
        return {
            checkType,
            startTime: checkDetails ? checkDetails.starttime : null,
            endTime: checkDetails ? checkDetails.endtime : null,
            applicable: checkDetails ? checkDetails.applicable : true
        };
    });

    const handleStartTest = async (stageType) => {
        const employeeId = user.posid;

        const response = await fetch(`${apiBaseUrl}/api/protected/startTest/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ employeeId, stageType }),
        });
        if (response.ok) {
            setShouldFetch(true);
        } else {
            console.error("Failed to update test start.");
        };
    };

    const handleCheckTestFailed = async (stageType) => {
        const failedTime = new Date();
        const employeeId = user.posid;

        const response = await fetch(`${apiBaseUrl}/api/protected/checkTestFailed/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ employeeId, failedTime, stageType }),
        });
        if (response.ok) {
            setShouldFetch(true);
        } else {
            console.error("Failed to update check or test as failed.");
        };
    };

    const isMoreThan24HoursAgo = (dateString) => {
        const date = new Date(dateString);
        const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);

        return date < twentyFourHoursAgo;
    };

    const handleFinishTest = async (stageType) => {
        setLoading(true);
        const finishTime = new Date();
        const employeeId = user.posid;

        const response = await fetch(`${apiBaseUrl}/api/protected/finishTest/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ employeeId, finishTime, stageType }),
        });
        if (response.ok) {
            setShouldFetch(true);
        } else {
            console.error("Failed to update test finish.");
        };
        setLoading(false);
    };

    const handleStartCheck = async (checkType, stageType) => {
        const startTime = new Date();
        const employeeId = user.posid;

        const response = await fetch(`${apiBaseUrl}/api/protected/startCheck/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ employeeId, startTime, checkType, stageType }),
        });
        if (response.ok) {
            setShouldFetch(true);
        } else {
            console.error("Failed to update check start.");
        };
    };

    const handleEndCheck = async (checkType, stageType) => {
        const endTime = new Date();

        const response = await fetch(`${apiBaseUrl}/api/protected/endCheck/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ endTime, checkType, stageType }),
        });
        if (response.ok) {
            setShouldFetch(true);
        } else {
            console.error("Failed to update check end.");
        };
    };

    const handleNotApplicable = async (checkType, stageType) => {
        const employeeId = user.posid;

        const response = await fetch(`${apiBaseUrl}/api/protected/notApplicable/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ employeeId, checkType, stageType }),
        });
        if (response.ok) {
            setShouldFetch(true);
        } else {
            console.error("Failed to mark check not applicable.");
        };
    };

    const handleUndo = async (checkType, stageType) => {
        const employeeId = user.posid;

        const response = await fetch(`${apiBaseUrl}/api/protected/undoCheck/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ employeeId, checkType, stageType }),
        });
        if (response.ok) {
            setShouldFetch(true);
        } else {
            console.error("Failed to undo check.");
        };
    };

    const updateCheckStage = async (stage) => {
        setLoading(true);
        const employeeId = user.posid;

        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/updateCheckStage/${jobId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ stage, employeeId })
            })
            if (response.ok) {
                setShouldFetch(true);
            } else {
                console.error("Failed to update stage.")
            }
        } catch (error) {
            console.error("Error updating stage: ", error)
        } finally {
            setLoading(false);
        };
    };

    const handleCollection = async () => {
        setLoading(true);
        const employeeId = user.posid;

        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/collection/${jobId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ employeeId, stage })
            })
            if (response.ok) {
                setShouldFetch(true);
            } else {
                console.error("Failed to update collection status.")
            }
        } catch (error) {
            console.error("Error updating collection status: ", error)
        } finally {
            setLoading(false);
        };
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <header className='header'>
                <h2>Job ID: {jobId}</h2>
            </header>
            <div className='back'>
                <button type="button" onClick={back}>Back</button>
            </div>
            <div className='jobTable'>
                <div className='table-wrapper mobileScroll'>
                    <table className='fitTable'>
                        <thead className='head'>
                            <tr>
                                <th>SKU</th>
                                <th>Part Received</th>
                                <th>POS</th>
                                <th>Serial Number</th>
                                <th>Under Warranty</th>
                                <th>Created At</th>
                                <th>Outlet Received</th>
                                <th>Collection Outlet</th>
                                <th>Customer Name</th>
                                <th>Customer Contact</th>
                                <th>Customer Email</th>
                                <th>VIP</th>
                                <th>Job Status</th>
                                <th>Fault(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{jobs.sku}</td>
                                <td>{jobs.partreceived}</td>
                                <td>{jobs.pos}</td>
                                <td>{jobs.serialnumber}</td>
                                <td>{warranty}</td>
                                <td>{new Date(jobs.timestamp).toLocaleString('en-gb')}</td>
                                <td>{jobs.outletreceived}</td>
                                <td>{jobs.outletcollection}</td>
                                <td>{jobs.customername}</td>
                                <td>{jobs.customercontact}</td>
                                <td>{jobs.customeremail}</td>
                                <td>{jobs.vip === 1 ? 'True' : 'False'}</td>
                                <td>{jobs.stageofrepair}</td>
                                <td>{jobs.fault1}, {jobs.fault2}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='margin'>
                {!loading && (
                    <div style={{ marginBottom: '1rem' }}>
                        <h4>Outlet Images:</h4>
                        {imageCheck.length !== 0 && (
                            <div className='image-buttons'>
                                {jobs.imageUrls.map((imageUrl, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'black',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={() => handleImageNameClick(jobs.imageUrls[index])}
                                    >
                                        {`imageIndex-${index + 1}`}
                                    </button>
                                ))}
                            </div>
                        )}
                        <Modal
                            isOpen={showImageModal}
                            onRequestClose={() => setShowImageModal(false)}
                            style={customStyle}
                        >
                            <div className="modal-content">
                                <button onClick={() => setShowImageModal(false)} className="modal-close-btn">x</button>
                                <img className='newJobImage' src={selectedImage} alt="Selected" />
                            </div>
                        </Modal>
                    </div>
                )}
                {stage === 'Outlet 24 Hour Test' && (
                    <div>
                        <h4>Outlet 24 Hour Test:</h4>
                        {!testFinish && !testStart && (<button onClick={() => handleStartTest('Outlet 24 Hour Test')}>Start</button>)}
                        {!testFinish && testStart && (<p>Started at: {new Date(testStart).toLocaleString('en-gb')}</p>)}
                        {!testFinish && testStart && isMoreThan24HoursAgo(testStart) && (<button onClick={() => setShowTestModal(true)} style={{ marginRight: '0.5rem' }}>Finish</button>)}
                        {testFinish && testStart && (<p>Complete {new Date(testFinish).toLocaleString('en-gb')}</p>)}
                        {testStart && (<button className='mobileButton' onClick={() => setShowTestFailModal(true)}>Test Failed</button>)}
                    </div>
                )}
                <Modal
                    isOpen={showTestModal}
                    onRequestClose={() => setShowTestModal(false)}
                    style={customStyle}
                >
                    <div className="modal-content">
                        <button onClick={() => setShowTestModal(false)} className="modal-close-btn">x</button>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <h3>Confirm test completed?</h3>
                        </div>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <p>This action cannot be undone.</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button onClick={() => { handleFinishTest('Outlet 24 Hour Test'); setShowTestModal(false); }}>Confirm</button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={showTestFailModal}
                    onRequestClose={() => setShowTestFailModal(false)}
                    style={customStyle}
                >
                    <div className="modal-content">
                        <button onClick={() => setShowTestFailModal(false)} className="modal-close-btn">x</button>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <h3>Confirm test failed?</h3>
                        </div>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <p>This action cannot be undone.</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button onClick={() => { handleCheckTestFailed('Outlet 24 Hour Test'); setShowTestFailModal(false); }}>Confirm</button>
                        </div>
                    </div>
                </Modal>
                {stage === 'Outlet Quality Check' && (
                    <div style={{ marginLeft: 0, marginRight: 0 }} className='table-wrapper mobileScroll'>
                        <table className='fitTable'>
                            <thead>
                                <tr className='head'>
                                    <th>Check Type</th>
                                    <th>Action / Status</th>
                                    <th>NA</th>
                                    <th>Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayChecks.map((check) => (
                                    <tr key={check.checkType}>
                                        <td>{check.checkType}</td>
                                        {check.applicable ?
                                            (check.endTime ?
                                                <td>Complete {new Date(check.endTime).toLocaleString('en-gb')}</td> :
                                                <td>{check.startTime ?
                                                    <button onClick={() => handleEndCheck(check.checkType, 'Outlet Quality Check')}>Confirm Working</button> :
                                                    <button onClick={() => handleStartCheck(check.checkType, 'Outlet Quality Check')}>Start Check</button>}
                                                </td>
                                            ) : <td>Not Applicable</td>}
                                        <td>{check.applicable && !check.startTime ? <button onClick={() => handleNotApplicable(check.checkType, 'Outlet Quality Check')}>Set NA</button> : '-'}</td>
                                        <td><button onClick={() => handleUndo(check.checkType, 'Outlet Quality Check')}>Restart Check</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {stage === 'Outlet Quality Check' && (
                    <div>
                        <button style={{ marginTop: '0.5rem' }} className='mobileButton' onClick={() => setShowCheckFailModal(true)}>Check Failed</button>
                        {QCComplete && (
                            <div>
                                <button style={{ marginTop: '0.5rem' }} className='mobileButton' onClick={() => updateCheckStage('Outlet Quality Check')}>Mark Outlet Quality Check Complete</button>
                            </div>
                        )}
                        <Modal
                            isOpen={showCheckFailModal}
                            onRequestClose={() => setShowCheckFailModal(false)}
                            style={customStyle}
                        >
                            <div className="modal-content">
                                <button onClick={() => setShowCheckFailModal(false)} className="modal-close-btn">x</button>
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                    <h3>Confirm check failed?</h3>
                                </div>
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                    <p>This action cannot be undone.</p>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <button onClick={() => { handleCheckTestFailed('Outlet 24 Hour Test'); setShowCheckFailModal(false); }}>Confirm</button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                )}
                {(stage === 'Ready for Collection' || stage === 'Ready for Collection (job cancelled)') && (
                    <div>
                        <button className='mobileButton' onClick={handleCollection}>Customer Collected</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OutletDetails;