import React, { useContext, useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { useParams, useNavigate, Navigate, useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';

import { UserContext } from './context';
import './assets/styles/repair.styles.css'
import repairCancelSlip from './assets/template/repairCancelSlip.pdf';

const { hostname } = window.location;
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

Modal.setAppElement('#root');

const ApprovalDetails = () => {
    const { user, accessToken, accessiblePages } = useContext(UserContext);
    const navigate = useNavigate();

    let { jobId } = useParams();
    const [jobs, setJobs] = useState([]);
    const [warranty, setWarranty] = useState('');
    const [loading, setLoading] = useState(true);

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const sortField = queryParams.get('sort') || '';
    const sortOrder = queryParams.get('order') || 'asc';
    const page = queryParams.get('page') || 1;

    const [filters,] = useState({
        sort: sortField,
        order: sortOrder,
        page: page
    });

    const [stage, setStage] = useState('');
    const [imageCheck, setImageCheck] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);

    const [outlets, setOutlets] = useState(null);
    const [findings, setFindings] = useState('');
    const [addedParts, setAddedParts] = useState([]);
    const [shouldFetch, setShouldFetch] = useState(false);
    const [parts, setParts] = useState(null);

    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [showCancellationModal, setShowCancellationModal] = useState(false);

    const [editMode, setEditMode] = useState(false);
    const [salesOrder, setSalesOrder] = useState('');
    const [salesOrderStart, setSalesOrderStart] = useState('');

    const back = () => {
        const { sort, order, page } = filters;
        navigate(`/approval?sort=${sort}&order=${order}&page=${page}`);
    };

    const inventoryData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/parts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ apiBaseUrl })
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();
            const modifiedParts = responseBody.results.map((part, index) => ({
                ...part.values,
                partid: part.values['GROUP(itemid)'].split(':')[1].trim(),
                index: index + 1,
                locationquantityonhand: Number(part.values['SUM(locationquantityonhand)']),
                unitprice: Number(part.values['SUM(baseprice)'] * 1.09).toFixed(2)
            }));
            setParts(modifiedParts);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/approval/${jobId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const imageUrls = data.images.map(imageData => {
                const uint8Array = new Uint8Array(imageData.imagedata.data);
                const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                const imageUrl = URL.createObjectURL(blob);
                return imageUrl;
            });

            setJobs({ ...data, imageUrls });
        } catch (error) {
            console.error("Failed to fetch job details:", error);
        } finally {
            setLoading(false);
        }
    }, [jobId, accessToken]);

    const outletsData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/outlets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ apiBaseUrl })
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();
            setOutlets(responseBody);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    useEffect(() => {
        if (accessiblePages.includes('approval')) {
            inventoryData();
            outletsData();
            fetchData();
        }
    }, [jobId, fetchData, accessiblePages, inventoryData, outletsData]);

    useEffect(() => {
        if (jobs.warranty) {
            setWarranty('True');
        } else {
            setWarranty('False');
        };
        if (jobs.charges?.length > 0) {
            const chargesData = jobs.charges[0];
            setSalesOrder(chargesData.salesorder || '');
            setSalesOrderStart(chargesData.salesorder || '');
        }
        setStage(jobs.stageofrepair);
        if (jobs.assessment) {
            setFindings(jobs.assessment.findings || '');
        };
        if (jobs.repairParts && parts) {
            const mappedParts = jobs.repairParts.map(repairPart => {
                const partDetails = parts.find(part => part.partid === repairPart.partid);
                return {
                    ...partDetails,
                    quantity: repairPart.quantity,
                    quantitysalvaged: repairPart.quantitysalvaged,
                    quantitychargeable: repairPart.quantitychargeable,
                    totalPrice: partDetails.unitprice * repairPart.quantitychargeable,
                    remarks: repairPart.remarks,
                    instock: repairPart.instock
                };
            });
            setAddedParts(mappedParts);
        };
        if (jobs.imageUrls) {
            setImageCheck(jobs.imageUrls);
        };
    }, [jobs, parts]);

    useEffect(() => {
        if (shouldFetch && accessiblePages.includes('approval')) {
            fetchData();
            setShouldFetch(false);
        };
    }, [shouldFetch, fetchData, accessiblePages]);

    if (!accessiblePages.includes('approval')) {
        return <Navigate to="/dashboard" />;
    };

    const handleChangeCollection = async (value) => {
        setLoading(true);
        const employeeId = user.posid;
        if (value === jobs.outletcollection) {
            setLoading(false);
            return;
        };
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/handleChangeCollection/${jobId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ value, employeeId, initialOutlet: jobs.outletcollection })
            });
            if (response.ok) {
                setShouldFetch(true);
            } else {
                console.error("Failed to update collection outlet.");
            };
        } catch (error) {
            console.error("Error updating collection outlet", error);
        } finally {
            setLoading(false);
        };
    };

    const handleImageNameClick = (image) => {
        setSelectedImage(image);
        setShowImageModal(true);
    };

    const handleCustomerApproval = async () => {
        setLoading(true);
        const employeeId = user.posid;
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/handleApproval/${jobId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ employeeId, stage })
            });
            if (response.ok) {
                setShouldFetch(true);
            } else {
                console.error("Failed to update approval status.");
            };
        } catch (error) {
            console.error("Error updating approval status", error);
        } finally {
            setLoading(false);
        };
    };

    const handleSaveCharges = async () => {
        const employeeId = user.posid;
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/saveCharges/${jobId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ employeeId, salesOrder, salesOrderStart, type: 'sales' })
            });
            if (response.ok) {
                setShouldFetch(true);
            } else {
                console.error("Failed to save charges.");
            };
        } catch (error) {
            console.error("Error saving charges:", error);
        };
    };

    const handleCancellation = async () => {
        setLoading(true);
        const employeeId = user.posid;
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/handleCancellation/${jobId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ employeeId, stage })
            });
            if (response.ok) {
                const existingPdfBytes = await fetch(repairCancelSlip).then(res => res.arrayBuffer());
                const pdfDoc = await PDFDocument.load(existingPdfBytes);
                const pages = pdfDoc.getPages();
                const firstPage = pages[0];
                const fontSize = 11;

                let today = new Date();
                const dd = String(today.getDate()).padStart(2, '0');
                const mm = String(today.getMonth() + 1).padStart(2, '0');
                const yyyy = today.getFullYear();
                today = dd + '/' + mm + '/' + yyyy;
                firstPage.drawText(jobs.customername, {
                    x: 75,
                    y: 178,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                });
                firstPage.drawText(jobs.customercontact, {
                    x: 75,
                    y: 162,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                });
                firstPage.drawText(`${jobId}`, {
                    x: 75,
                    y: 146,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                });
                firstPage.drawText(jobs.sku, {
                    x: 75,
                    y: 131,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                });
                firstPage.drawText(jobs.partreceived, {
                    x: 75,
                    y: 115,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                });
                firstPage.drawText(jobs.outletreceived, {
                    x: 75,
                    y: 98,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                });
                firstPage.drawText(jobs.outletcollection, {
                    x: 75,
                    y: 82,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                });
                firstPage.drawText(today, {
                    x: 75,
                    y: 66,
                    size: fontSize,
                    color: rgb(0, 0, 0),
                });
                const pdfBytes = await pdfDoc.save();
                const blob = new Blob([pdfBytes], { type: 'application/pdf' });
                saveAs(blob, `Repair Cancellation Slip ${jobId}.pdf`);
                setShouldFetch(true);
            } else {
                console.error("Failed to update cancellation status.");
            };
        } catch (error) {
            console.error("Error updating cancellation status", error);
        } finally {
            setLoading(false);
        };
    };

    const customStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <header className='header'>
                <h2>Job ID: {jobId}</h2>
            </header>
            <div className='back'>
                <button type="button" onClick={back}>Back</button>
            </div>
            <div className='jobTable'>
                <div className='table-wrapper mobileScroll'>
                    <table className='fitTable'>
                        <thead>
                            <tr className='head'>
                                <th>SKU</th>
                                <th>Part Received</th>
                                <th>POS</th>
                                <th>Serial Number</th>
                                <th>Under Warranty</th>
                                <th>Created At</th>
                                <th>Outlet Received</th>
                                <th>Collection Outlet</th>
                                <th>Customer Name</th>
                                <th>Customer Contact</th>
                                <th>Customer Email</th>
                                <th>VIP</th>
                                <th>Job Status</th>
                                <th>Fault(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{jobs.sku}</td>
                                <td>{jobs.partreceived}</td>
                                <td>{jobs.pos}</td>
                                <td>{jobs.serialnumber}</td>
                                <td>{warranty}</td>
                                <td>{new Date(jobs.timestamp).toLocaleString('en-gb')}</td>
                                <td>{jobs.outletreceived}</td>
                                <td>
                                    <select defaultValue={""} onChange={(e) => handleChangeCollection(e.target.value)} autoFocus={false} required>
                                        <option value="" disabled hidden>{jobs.outletcollection}</option>
                                        {outlets && outlets.map((outlet) => (
                                            <option key={outlet.id} value={outlet.name.split('|')[1]}>
                                                {outlet.name.split('|')[1]}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>{jobs.customername}</td>
                                <td>{jobs.customercontact}</td>
                                <td>{jobs.customeremail}</td>
                                <td>{jobs.vip === 1 ? 'True' : 'False'}</td>
                                <td>{jobs.stageofrepair}</td>
                                <td>{jobs.fault1}, {jobs.fault2}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='margin'>
                <div>
                    <h4>Customer Remarks:</h4>
                    <p>{jobs.remarks}</p>
                </div>
                {!loading && (
                    <div>
                        <h4>Outlet Images:</h4>
                        {imageCheck.length !== 0 && (
                            <div className='image-buttons'>
                                {jobs.imageUrls.map((imageUrl, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'black',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={() => handleImageNameClick(jobs.imageUrls[index])}
                                    >
                                        {`imageIndex-${index + 1}`}
                                    </button>
                                ))}
                            </div>
                        )}
                        <Modal
                            isOpen={showImageModal}
                            onRequestClose={() => setShowImageModal(false)}
                            style={customStyle}
                        >
                            <div className="modal-content">
                                <button onClick={() => setShowImageModal(false)} className="modal-close-btn">x</button>
                                <img className='newJobImage' src={selectedImage} alt="Selected" />
                            </div>
                        </Modal>
                    </div>
                )}
                {!loading && (
                    <div>
                        <h4>Assessment and Repair Details:</h4>
                        <div>
                            <div className='findingsDiv'>
                                <input className='findingsInput' type="text" value={findings} disabled />
                            </div>
                        </div>
                        <div style={{ marginLeft: 0, marginRight: 0, marginBottom: '1rem' }} className='table-wrapper mobileScroll'>
                            <table className='fitTable'>
                                <thead>
                                    <tr className='head'>
                                        <th>SKU</th>
                                        <th>Description</th>
                                        <th>Unit Price</th>
                                        <th>Quantity Required</th>
                                        <th>Quantity Salvaged</th>
                                        <th>Quantity Chargeable</th>
                                        <th>Stock (Hysses)</th>
                                        <th>Available for Repair?</th>
                                        <th>Total Price</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addedParts.map((part, index) => (
                                        <tr key={index}>
                                            <td>{part.partid}</td>
                                            <td>{part['GROUP(salesdescription)']}</td>
                                            <td>{part?.unitprice ? part.unitprice : ''}</td>
                                            <td>{part.quantity}</td>
                                            <td>{part.quantitysalvaged}</td>
                                            {jobs.warranty === 0 && (
                                                <td>{part.quantitychargeable === null ? '0' : part.quantitychargeable}</td>
                                            )}
                                            {jobs.warranty === 1 && (<td>NA</td>)}
                                            <td>{part.locationquantityonhand}</td>
                                            <td>{part.instock === 1 ? 'True' : 'False'}</td>
                                            <td>{parseFloat(part.totalPrice).toFixed(2)}</td>
                                            <td>{part.remarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="8">Total Net Price</td>
                                        <td>{addedParts.reduce((acc, part) => acc + parseFloat(part.totalPrice), 0).toFixed(2)}</td>
                                    </tr>
                                    {jobs.vip === 1 && (
                                        <tr>
                                            <td colSpan="8">Total VIP Price</td>
                                            <td>{((addedParts.reduce((acc, part) => acc + parseFloat(part.totalPrice), 0)) * 0.9).toFixed(2)}</td>
                                        </tr>
                                    )}
                                </tfoot>
                            </table>
                        </div>
                    </div>
                )}
                {jobs.warranty === 0 && !loading && (
                    <div style={{ marginLeft: 0, marginRight: 0 }} className='table-wrapper mobileScroll'>
                        <table style={{ marginBottom: '0.5rem' }} className='fitTable'>
                            <thead>
                                <tr className='head'>
                                    <th>Items</th>
                                    <th>Reference No.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Hysses Sales Order</td>
                                    {!editMode && (
                                        <td>{salesOrder}</td>
                                    )}
                                    {editMode && (
                                        <td><input type='text' value={salesOrder} onChange={e => setSalesOrder(e.target.value)} maxLength={100} /></td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                        {!editMode && (
                            <button className='mobileButton' onClick={() => { setEditMode(true); }}>Edit</button>
                        )}
                        {editMode && (
                            <button className='mobileButton' onClick={() => { setEditMode(false); handleSaveCharges(); }}>Save</button>
                        )}
                    </div>
                )}
                {(stage === 'Waiting for Approval (parts ordering)' || stage === 'Waiting for Approval (repair charges)' || stage === 'Waiting for Approval (charges and parts)') && !editMode && (jobs.warranty || salesOrder !== '') && (
                    <div style={{ marginTop: '1rem' }}>
                        <button className='mobileButton' onClick={() => setShowApprovalModal(true)}>Customer Called and Approved</button>
                    </div>
                )}
                {(stage === 'Waiting for Approval (parts ordering)' || stage === 'Waiting for Approval (repair charges)' || stage === 'Waiting for Approval (charges and parts)') && !editMode && (
                    <div style={{ marginTop: '1rem' }}>
                        <button className='mobileButton' onClick={() => setShowCancellationModal(true)}>Customer Cancelled</button>
                    </div>
                )}
                <div>
                    <Modal
                        isOpen={showApprovalModal}
                        onRequestClose={() => setShowApprovalModal(false)}
                        style={customStyle}
                    >
                        <div className="modal-content">
                            <button onClick={() => setShowApprovalModal(false)} className="modal-close-btn">x</button>
                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                <h3>Confirm customer approval?</h3>
                            </div>
                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                <p>This action cannot be undone.</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button onClick={() => { handleCustomerApproval(); setShowApprovalModal(false); }}>Confirm</button>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div>
                    <Modal
                        isOpen={showCancellationModal}
                        onRequestClose={() => setShowCancellationModal(false)}
                        style={customStyle}
                    >
                        <div className="modal-content">
                            <button onClick={() => setShowCancellationModal(false)} className="modal-close-btn">x</button>
                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                <h3>Confirm customer cancellation?</h3>
                            </div>
                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                <p>This action cannot be undone.</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button onClick={() => { handleCancellation(); setShowCancellationModal(false); }}>Confirm</button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </div >
    );
};

export default ApprovalDetails;