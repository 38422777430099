import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import ReactLoading from 'react-loading';

import { UserContext } from "./context";
import './assets/styles/repair.styles.css'

const { hostname } = window.location;
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

Modal.setAppElement('#root');

export default function Inventory() {
    const { accessToken } = useContext(UserContext);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [dateStartFilter, setDateStartFilter] = useState('');
    const [dateEndFilter, setDateEndFilter] = useState('');

    const [parts, setParts] = useState(null);

    const [updating, setUpdating] = useState(false);
    const [salvagedOwedInputs, setSalvagedOwedInputs] = useState({});

    const back = () => {
        navigate('/dashboard');
    };

    const testNetsuite = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/parts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ apiBaseUrl })
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();

            const modifiedParts = responseBody.results.map((part, index) => ({
                ...part.values,
                partid: part.values['GROUP(itemid)'].split(':')[1].trim(),
                index: index + 1,
                locationquantityonhand: Number(part.values['SUM(locationquantityonhand)']),
                unitprice: Number(part.values['SUM(baseprice)'] * 1.09).toFixed(2)
            }));

            const totalUsed = {};
            responseBody.usedPartsResult.forEach(part => {
                const partId = part.partid;
                if (totalUsed[partId]) {
                    totalUsed[partId] += part.quantity;
                } else {
                    totalUsed[partId] = part.quantity;
                }
            });

            const currentlyRequired = {};
            const requiredStages = ["Waiting for Approval (parts ordering)", "Waiting for Approval (charges and parts)", "Waiting for Accounting and Spare Parts", "Waiting for Spare Parts"];
            responseBody.usedPartsResult.forEach(part => {
                const partId = part.partid;
                if (requiredStages.includes(part.stageOfRepair)) {
                    if (currentlyRequired[partId]) {
                        if (part.instock === 0) {
                            currentlyRequired[partId] += part.quantity;
                        }
                    } else {
                        if (part.instock === 0) {
                            currentlyRequired[partId] = part.quantity;
                        }
                    }
                }
            });

            const salvagedOwed = {};
            responseBody.usedPartsResult.forEach(part => {
                const partId = part.partid;
                if (currentlyRequired[partId]) {
                    currentlyRequired[partId] += part.quantitysalvaged;
                } else {
                    currentlyRequired[partId] = part.quantitysalvaged;
                }
                if (salvagedOwed[partId]) {
                    salvagedOwed[partId] += part.quantitysalvaged;
                } else {
                    salvagedOwed[partId] = part.quantitysalvaged;
                }
            });

            const partsWithUsage = modifiedParts.map(part => ({
                ...part,
                totalUsed: totalUsed[part.partid] || 0,
                currentlyRequired: currentlyRequired[part.partid] || 0,
                salvagedOwed: salvagedOwed[part.partid] || 0
            }));

            const finishedPartList = partsWithUsage.map(part => ({
                ...part,
                required: part['currentlyRequired'] - part['locationquantityonhand']
            }));

            setParts(finishedPartList);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    useEffect(() => {
        testNetsuite();
    }, [testNetsuite]);

    const sortParts = (field) => {
        if (!parts) return;

        const order = sortField === field && sortOrder === 'desc' ? 'asc' : 'desc';
        setSortField(field);
        setSortOrder(order);

        const sortedParts = [...parts].sort((a, b) => {
            let valA = a[field];
            let valB = b[field];

            if (field === 'SUM(locationquantityonhand)') {
                if (valA === null) {
                    valA = 0;
                };
                if (valB === null) {
                    valB = 0;
                };
            };

            if (field === 'SUM(baseprice)' || field === 'unitprice') {
                valA = parseFloat(valA);
                valB = parseFloat(valB);
            }

            if (valA < valB) {
                return order === 'asc' ? -1 : 1;
            }
            if (valA > valB) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        });

        setParts(sortedParts);
    };

    const updateSalvagedOwed = async () => {
        setLoading(true);
        setUpdating(false);

        try {
            const updates = Object.entries(salvagedOwedInputs).map(([partid, inputValue]) => ({
                partid,
                quantitySalvaged: parseInt(inputValue) || 0
            }));

            await fetch(`${apiBaseUrl}/api/protected/updateSalvagedOwed`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ updates })
            });

            setSalvagedOwedInputs({});
            testNetsuite();
        } catch (error) {
            console.error('Error updating salvaged owed:', error);
        } finally {
            setLoading(false);
        }
    };

    const downloadCSV = async () => {
        setLoading(true);
        try {
            const queryParams = new URLSearchParams({
                dateStartFilter,
                dateEndFilter,
                apiBaseUrl
            });

            const response = await fetch(`${apiBaseUrl}/api/protected/inventoryDownload?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;

                // Format the date range for the filename
                const formattedStartDate = dateStartFilter.replace(/-/g, '');
                const formattedEndDate = dateEndFilter.replace(/-/g, '');
                const filename = `write-off-data-${formattedStartDate}-${formattedEndDate}.csv`;

                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error("Failed to download CSV file.");
            }
        } catch (error) {
            console.error("Error downloading CSV file", error);
        }
        setLoading(false);
    };

    const handleSalvagedOwedChange = (e, part) => {
        let value = e.target.value;
        const max = part['salvagedOwed'];
        value = Math.max(0, Math.min(max, Number(value)));

        setSalvagedOwedInputs(prevState => ({
            ...prevState,
            [part.partid]: value
        }));
    };

    const customStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <header className='header'>
                <h1>Repair Part Inventory</h1>
            </header>
            <div className='back spaceBetween'>
                <div>
                    <button type="button" onClick={back}>Back</button>
                </div>
                <div>
                    <button type="button" onClick={() => setShowDownloadModal(true)} style={{ marginRight: '1rem' }}>Write Off Data</button>
                    {!updating && (<button type="button" onClick={() => setUpdating(true)}>Return Salvaged</button>)}
                    {updating && (<button type="button" onClick={updateSalvagedOwed}>Save Changes</button>)}
                </div>
            </div>
            <div>
                <Modal
                    isOpen={showDownloadModal}
                    onRequestClose={() => setShowDownloadModal(false)}
                    style={customStyle}
                >
                    <div className="modal-content">
                        <button onClick={() => setShowDownloadModal(false)} className="modal-close-btn">x</button>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <h3>Select date range</h3>
                        </div>
                        <div className='dateFlexAlign'>
                            <label style={{ marginRight: '0.5rem' }}>Date:</label><br style={{ flexBasis: '100%', height: 0 }} />
                            <div className='date'>
                                <input type="date" className='dateInput' value={dateStartFilter} onChange={(e) => { setDateStartFilter(e.target.value); }} />
                                <p style={{ paddingLeft: '3px', paddingRight: '3px' }}>-</p>
                                <input type="date" className='dateInput' value={dateEndFilter} onChange={(e) => { setDateEndFilter(e.target.value); }} />
                                <button style={{ marginLeft: '1rem' }} onClick={() => { setDateStartFilter(''); setDateEndFilter(''); }}>X</button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button onClick={() => { downloadCSV(); setShowDownloadModal(false); }}>Download</button>
                        </div>
                    </div>
                </Modal>
            </div>
            <div>
                <div className='table-wrapper mobileScroll'>
                    <table className='fitTable mobileScroll' style={{ marginBottom: '1rem' }}>
                        <thead>
                            <tr className='head'>
                                <th className='sort' onClick={() => sortParts('index')}>Index {sortField === 'index' && (sortOrder === 'asc' ? '↓' : '↑')}</th>
                                <th className='sort' onClick={() => sortParts('partid')}>Part ID {sortField === 'partid' && (sortOrder === 'asc' ? '↓' : '↑')}</th>
                                <th className='sort' onClick={() => sortParts('GROUP(salesdescription)')}>GROUP(salesdescription) {sortField === 'GROUP(salesdescription)' && (sortOrder === 'asc' ? '↓' : '↑')}</th>
                                <th className='sort' onClick={() => sortParts('SUM(baseprice)')}>Base Price {sortField === 'SUM(baseprice)' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortParts('unitprice')}>Unit Price {sortField === 'unitprice' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortParts('locationquantityonhand')}>Stock (Hysses) {sortField === 'locationquantityonhand' && (sortOrder === 'asc' ? '↓' : '↑')}</th>
                                <th className='sort' onClick={() => sortParts('currentlyRequired')}>Required to Buy {sortField === 'currentlyRequired' && (sortOrder === 'asc' ? '↓' : '↑')}</th>
                                <th className='sort' onClick={() => sortParts('salvagedOwed')}>Salvaged Owed {sortField === 'salvagedOwed' && (sortOrder === 'asc' ? '↓' : '↑')}</th>
                                <th className='sort' onClick={() => sortParts('totalUsed')}>Total Used + to be Used {sortField === 'totalUsed' && (sortOrder === 'asc' ? '↓' : '↑')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {parts && parts.map((part) => (
                                <tr key={part.index}>
                                    <td>{part['index']}</td>
                                    <td>{part['partid']}</td>
                                    <td>{part['GROUP(salesdescription)']}</td>
                                    <td>${part['SUM(baseprice)']}</td>
                                    <td>${part['unitprice']}</td>
                                    <td>{part['locationquantityonhand']}</td>
                                    <td>{part['currentlyRequired']}</td>
                                    {!updating && (<td>{part['salvagedOwed']}</td>)}
                                    {updating && part['salvagedOwed'] > 0 && (
                                        <td>
                                            {part['salvagedOwed']} - &nbsp;
                                            <input
                                                type="number"
                                                value={salvagedOwedInputs[part.partid] || ''}
                                                onChange={(e) => handleSalvagedOwedChange(e, part)}
                                                style={{ width: '30px' }}
                                            />
                                        </td>
                                    )}
                                    {updating && part['salvagedOwed'] <= 0 && (<td>{part['salvagedOwed']}</td>)}
                                    <td>{part['totalUsed']}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    );
};