import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { useParams, useNavigate, Navigate, useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';

import { UserContext } from './context';
import './assets/styles/repair.styles.css'
import repairCompleteSlip from './assets/template/repairCompleteSlip.pdf';

const { hostname } = window.location;
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

Modal.setAppElement('#root');

const RepairDetails = () => {
    const { user, accessToken, accessiblePages } = useContext(UserContext);
    const navigate = useNavigate();

    let { jobId } = useParams();
    const [jobs, setJobs] = useState([]);
    const [warranty, setWarranty] = useState('');
    const [loading, setLoading] = useState(true);

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const sortField = queryParams.get('sort') || '';
    const sortOrder = queryParams.get('order') || 'asc';
    const page = queryParams.get('page') || 1;

    const [filters,] = useState({
        sort: sortField,
        order: sortOrder,
        page: page
    });

    const [stage, setStage] = useState('');
    const [imageCheck, setImageCheck] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);

    const [findings, setFindings] = useState('');
    const [fEditMode, setFEditMode] = useState(false);
    const [selectedPart, setSelectedPart] = useState('');
    const [inStock, setInStock] = useState(1);
    const [quantity, setQuantity] = useState('');
    const [quantityChargeable, setQuantityChargeable] = useState('');
    const [quantitySalvaged, setQuantitySalvaged] = useState('');
    const [partRemarks, setPartRemarks] = useState('');
    const [addedParts, setAddedParts] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [shouldSave, setShouldSave] = useState(false);
    const [assessmentComplete, setAssessmentComplete] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(false);
    const [showAssessmentModal, setShowAssessmentModal] = useState(false);

    const [parts, setParts] = useState(null);
    const [showAvailableModal, setShowAvailableModal] = useState(false);

    const [showRepairModal, setShowRepairModal] = useState(false);

    const [testStart, setTestStart] = useState(null);
    const [testFinish, setTestFinish] = useState(null);
    const [showTest1Modal, setShowTest1Modal] = useState(false);
    const [showRestart1Modal, setShowRestart1Modal] = useState(false);

    const [QC1Complete, setQC1Complete] = useState(false);

    const [test2Start, setTest2Start] = useState(null);
    const [test2Finish, setTest2Finish] = useState(null);
    const [showTest2Modal, setShowTest2Modal] = useState(false);
    const [showRestart2Modal, setShowRestart2Modal] = useState(false);

    const [QC2Complete, setQC2Complete] = useState(false);

    const back = () => {
        const { sort, order, page } = filters;
        navigate(`/repairQC?sort=${sort}&order=${order}&page=${page}`);
    };

    const checks = useMemo(() => [
        'Visible Mist?',
        'Visible LED Lights (7 colours)?',
        'Switch (Low, Med, High) working fine?',
        'Switch (On, Off) working fine?',
        'Intermittent Rest (2 M/ins, 1 Min) functioning?',
        'Slight Vibration?',
        'No unusual sound / noisy (aside from normal vibration sound)?',
        'Glass chamber inner tube not broken / chipped?',
        'No residual oil inside the diffuser and on the glass (To clean with cleaning solution and air dry)?',
        'No water mark inside the diffuser and on the glass (To clean with cleaning solution and air dry)?',
        'Visual check rubber in good condition and right position?',
        'Adaptor functioning?'
    ], []);

    const inventoryData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/parts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ apiBaseUrl })
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();
            const modifiedParts = responseBody.results.map((part, index) => ({
                ...part.values,
                partid: part.values['GROUP(itemid)'].split(':')[1].trim(),
                index: index + 1,
                locationquantityonhand: Number(part.values['SUM(locationquantityonhand)']),
                unitprice: Number(part.values['SUM(baseprice)'] * 1.09).toFixed(2)
            }));
            setParts(modifiedParts);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        };
    }, [accessToken]);

    const fetchData = useCallback(async (render) => {
        if (render) {
            setLoading(true);
        }
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/repairQC/${jobId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const imageUrls = data.images.map(imageData => {
                const uint8Array = new Uint8Array(imageData.imagedata.data);
                const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                const imageUrl = URL.createObjectURL(blob);
                return imageUrl;
            });

            setJobs({ ...data, imageUrls });
        } catch (error) {
            console.error("Failed to fetch job details:", error);
        }
    }, [jobId, accessToken]);

    useEffect(() => {
        if (accessiblePages.includes('repairQC')) {
            inventoryData();
            fetchData(true);
        };
    }, [jobId, fetchData, accessiblePages, inventoryData]);

    const isQualityCheck1Complete = useCallback(() => {
        const qualityCheck1Details = jobs.checkDetails.filter(check => check.stagetype === 'Quality Check 1');

        const allChecksPresent = checks.every(checkType =>
            qualityCheck1Details.some(check => check.checktype === checkType)
        );

        if (!allChecksPresent) return false;

        return qualityCheck1Details.every(check => check.endtime || !check.applicable);
    }, [jobs, checks]);

    const isQualityCheck2Complete = useCallback(() => {
        const qualityCheck2Details = jobs.checkDetails2.filter(check => check.stagetype === 'Quality Check 2');

        const allChecksPresent = checks.every(checkType =>
            qualityCheck2Details.some(check => check.checktype === checkType)
        );

        if (!allChecksPresent) return false;

        return qualityCheck2Details.every(check => check.endtime || !check.applicable);
    }, [jobs, checks]);

    useEffect(() => {
        setLoading(true);
        if (jobs.warranty) {
            setWarranty('True');
        } else {
            setWarranty('False');
        };
        setStage(jobs.stageofrepair);
        if (jobs.assessment) {
            setFindings(jobs.assessment.findings || '');
        };
        if (jobs.assessment) {
            setAssessmentComplete(jobs.assessment.finishTime || false);
        };
        if (jobs.repairParts && parts) {
            const mappedParts = jobs.repairParts.map(repairPart => {
                const partDetails = parts.find(part => part.partid === repairPart.partid);
                if (partDetails) {
                    return {
                        ...partDetails,
                        quantity: repairPart.quantity,
                        quantitysalvaged: repairPart.quantitysalvaged,
                        quantitychargeable: repairPart.quantitychargeable,
                        instock: repairPart.instock,
                        totalPrice: partDetails.unitprice * repairPart.quantitychargeable,
                        remarks: repairPart.remarks
                    };
                };
                return repairPart;
            });
            setAddedParts(mappedParts);
        };
        if (jobs.checkDetails && jobs.stageofrepair === 'Quality Check 1') {
            const qualityCheck1IsComplete = isQualityCheck1Complete();
            setQC1Complete(qualityCheck1IsComplete);
        };
        if (jobs.checkDetails && jobs.stageofrepair === 'Quality Check 2') {
            const qualityCheck2IsComplete = isQualityCheck2Complete();
            setQC2Complete(qualityCheck2IsComplete);
        };
        if (jobs.test) {
            setTestStart(jobs.test.startTime || '');
            setTestFinish(jobs.test.finishTime || '');
            setTest2Start(jobs.test2.startTime || '');
            setTest2Finish(jobs.test2.finishTime || '');
        };
        if (jobs.imageUrls) {
            setImageCheck(jobs.imageUrls);
        };
        setLoading(false);
    }, [jobs, isQualityCheck1Complete, isQualityCheck2Complete, parts]);

    const allowEditStages = [
        'Received and Awaiting Assessment',
        'Waiting for Spare Parts',
        'Repair Process',
        '24 Hour Test 1',
        'Quality Check 1',
        '24 Hour Test 2',
        'Quality Check 2'
    ];

    const handleFSave = async () => {
        const employeeId = user.posid;
        const f = findings;
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/saveFindings/${jobId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ employeeId, f })
            });
            if (response.ok) {
                setShouldFetch(true);
            } else {
                console.error("Failed to save findings.");
            };
        } catch (error) {
            console.error("Error saving findings:", error);
        }
    };

    const handleCheckboxChange = (value) => {
        if (value === 1) {
            setInStock(0)
        } else if (value === 0) {
            setInStock(1);
        }
    };

    const handleAddPart = () => {
        const part = parts.find(p => p.partid === selectedPart);
        if (quantitySalvaged === null) {
            setQuantitySalvaged(0)
        }
        if (quantityChargeable === null) {
            setQuantityChargeable(0)
        }
        if (Number(quantityChargeable) > Number(quantity)) {
            setAlertMessage('Quantity chargeable cannot be more than quantity required.');
            return;
        }
        if (Number(quantitySalvaged) > Number(quantity)) {
            setAlertMessage('Quantity salvaged cannot be more than quantity required.');
            return;
        }
        if (inStock === 0 && Number(quantitySalvaged) >= Number(quantity)) {
            setAlertMessage('Quantity salvaged cannot be more than or equal to quantity required if available for repair.');
            return;
        }
        if (part && quantity && parseInt(quantity) > 0) {
            const existingPartIndex = addedParts.findIndex(p => p.partid === part.partid);
            if (existingPartIndex !== -1) {
                setAlertMessage('Part already exists in the list.');
                return;
            } else {
                setAddedParts([...addedParts,
                {
                    quantity: parseInt(quantity),
                    quantitysalvaged: parseInt(quantitySalvaged),
                    quantitychargeable: parseInt(quantityChargeable),
                    instock: inStock,
                    totalPrice: parseInt(quantityChargeable) * part.unitprice,
                    remarks: partRemarks,
                    partid: part.partid,
                    employeeid: user.posid
                }
                ]);
                setSelectedPart('');
                setQuantity('');
                setQuantitySalvaged('');
                setQuantityChargeable('');
                setInStock(1);
                setPartRemarks('');
                setShouldSave(true);
                setAlertMessage('');
            }
        }
    };

    const handleQuantityChange = (index, newQuantity, type) => {
        setAlertMessage('');
        const newParts = [...addedParts];
        if (type === 'quantity') {
            if (newParts[index].instock === 0 && parseInt(newQuantity) === newParts[index].quantitysalvaged) {
                setAlertMessage('Quantity salvaged cannot be more than or equal to quantity required if available for repair.');
                return;
            } else if (newParts[index].quantitysalvaged > parseInt(newQuantity)) {
                setAlertMessage('Quantity salvaged cannot be more than quantity required.');
                return;
            } else if (newParts[index].quantitychargeable > parseInt(newQuantity)) {
                setAlertMessage('Quantity chargeable cannot be more than quantity required.');
                return;
            };
        }
        if (type === 'quantitysalvaged' && newParts[index].instock === 0 && parseInt(newQuantity) === newParts[index].quantity) {
            setAlertMessage('Quantity salvaged cannot be more than or equal to quantity required if available for repair.');
            return;
        } else if (type === 'quantitysalvaged' && newParts[index].quantity < parseInt(newQuantity)) {
            setAlertMessage('Quantity salvaged cannot be more than quantity required.');
            return;
        } else if (type === 'quantitychargeable' && newParts[index].quantity < parseInt(newQuantity)) {
            setAlertMessage('Quantity chargeable cannot be more than quantity required.');
            return;
        };
        if (parseInt(newQuantity) < 1 || newQuantity === '') {
            if (type === 'quantity') {
                newParts[index].quantity = 1;
            } else if (type === 'quantitysalvaged') {
                newParts[index].quantitysalvaged = 0;
            } else if (type === 'quantitychargeable') {
                newParts[index].quantitychargeable = 0;
            };
            if (type === 'quantitychargeable') {
                newParts[index].totalPrice = parseFloat(newParts[index].unitprice).toFixed(2);
            };
        } else {
            if (type === 'quantity') {
                newParts[index].quantity = parseInt(newQuantity);
            } else if (type === 'quantitysalvaged') {
                newParts[index].quantitysalvaged = parseInt(newQuantity);
            } else if (type === 'quantitychargeable') {
                newParts[index].quantitychargeable = parseInt(newQuantity);
            };
            if (type === 'quantitychargeable') {
                newParts[index].totalPrice = parseFloat(parseInt(newQuantity) * newParts[index].unitprice).toFixed(2);
            };
        };
        setAddedParts(newParts);
    };

    const handleInStockChange = (index, value) => {
        setAlertMessage('');
        const newParts = [...addedParts];
        if (value === 0) {
            newParts[index].instock = 1
        } else if (value === 1) {
            if (newParts[index].quantitysalvaged === newParts[index].quantity) {
                setAlertMessage('Quantity salvaged cannot be more than or equal to quantity required if available for repair.');
                return
            }
            newParts[index].instock = 0
            newParts[index].quantitysalvaged = 0
        }
        setAddedParts(newParts);
    };

    const handleRemarksChange = (index, newRemarks) => {
        const newParts = [...addedParts];
        newParts[index].remarks = newRemarks;
        setAddedParts(newParts);
    };

    const handleRemovePart = (index) => {
        const newParts = [...addedParts];
        newParts.splice(index, 1);
        setAddedParts(newParts);
    };

    const handleRestartTest = async (stageType) => {
        setLoading(true);
        const employeeId = user.posid;
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/restartTest/${jobId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ employeeId, stageType })
            });
            if (response.ok) {
                setShouldFetch(true);
            } else {
                console.error("Failed to restart test.");
            };
        } catch (error) {
            console.error("Error restarting test:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = useCallback(async () => {
        setLoading(true);
        setAlertMessage('');
        const posid = user.posid;
        const employeeId = user.posid;
        const partsData = addedParts.map(part => ({
            partid: part.partid,
            quantity: part.quantity,
            quantitysalvaged: part.instock === 0 ? 0 : part.quantitysalvaged,
            quantitychargeable: part.quantitychargeable,
            instock: part.instock,
            remarks: part.remarks,
            posid: posid
        }));
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/saveParts/${jobId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ partsData, stage, employeeId })
            });
            if (response.ok) {
                setShouldFetch(true);
            } else {
                console.error("Failed to save parts.");
            };
        } catch (error) {
            console.error("Error saving parts:", error);
        } finally {
            setLoading(false);
        }
    }, [user.posid, addedParts, jobId, accessToken, stage]);

    useEffect(() => {
        if (shouldSave) {
            handleSave();
            setShouldSave(false);
        };
    }, [shouldSave, handleSave]);

    const handleFinishAssessment = async () => {
        setLoading(true);
        const employeeId = user.posid;
        const partsData = addedParts.map(part => ({
            partId: part.partid,
            quantity: part.quantity,
            quantitysalvaged: part.quantitysalvaged,
            quantitychargeable: part.quantitychargeable,
            remarks: part.remarks,
            employeeId: employeeId,
            instock: part.instock
        }));
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/finishAssessment/${jobId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ employeeId, warranty: jobs.warranty, partsData })
            });
            if (response.ok) {
                setAssessmentComplete(true);
                setShouldFetch(true);
            } else {
                console.error("Failed to update assessment status.");
            };
        } catch (error) {
            console.error("Error updating asssessment status:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (shouldFetch && accessiblePages.includes('repairQC')) {
            fetchData(false);
            setShouldFetch(false);
        };
    }, [shouldFetch, fetchData, accessiblePages]);

    if (!accessiblePages.includes('repairQC')) {
        return <Navigate to="/dashboard" />;
    };

    const handleImageNameClick = (image) => {
        setSelectedImage(image);
        setShowImageModal(true);
    };

    const handlePartsAvailable = async () => {
        setLoading(true);
        const employeeId = user.posid;

        const response = await fetch(`${apiBaseUrl}/api/protected/partsAvailable/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ employeeId }),
        })
        if (response.ok) {
            setStage('Repair Process');
            setShouldFetch(true);
        } else {
            console.error("Failed to update repair status.");
        };
        setLoading(false);
    };

    const completeRepair = async () => {
        setLoading(true);
        const employeeId = user.posid;

        const response = await fetch(`${apiBaseUrl}/api/protected/completeRepair/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ employeeId }),
        })
        if (response.ok) {
            setStage('24 Hour Test 1');
            setShouldFetch(true);
        } else {
            console.error("Failed to update repair status.");
        };
        setLoading(false);
    };

    const customStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const displayChecks = checks.map(checkType => {
        const checkDetails = jobs.checkDetails ? jobs.checkDetails.find(check => check.checktype === checkType) : null;
        return {
            checkType,
            startTime: checkDetails ? checkDetails.starttime : null,
            endTime: checkDetails ? checkDetails.endtime : null,
            applicable: checkDetails ? checkDetails.applicable : true
        };
    });

    const displayChecks2 = checks.map(checkType => {
        const checkDetails = jobs.checkDetails2 ? jobs.checkDetails2.find(check => check.checktype === checkType) : null;
        return {
            checkType,
            startTime: checkDetails ? checkDetails.starttime : null,
            endTime: checkDetails ? checkDetails.endtime : null,
            applicable: checkDetails ? checkDetails.applicable : true
        };
    });

    const handleStartTest = async (stageType) => {
        setLoading(true);
        const employeeId = user.posid;

        const response = await fetch(`${apiBaseUrl}/api/protected/startTest/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ employeeId, stageType }),
        });
        if (response.ok) {
            setShouldFetch(true);
        } else {
            console.error("Failed to update test start.");
        };
        setLoading(false);
    };

    const isMoreThan24HoursAgo = (dateString) => {
        const date = new Date(dateString);
        const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);

        return date < twentyFourHoursAgo;
    };

    const handleFinishTest = async (stageType) => {
        setLoading(true);
        const employeeId = user.posid;

        const response = await fetch(`${apiBaseUrl}/api/protected/finishTest/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ employeeId, stageType }),
        });
        if (response.ok) {
            setShouldFetch(true);
        } else {
            console.error("Failed to update test finish.");
        };
        setLoading(false);
    };

    const handleStartCheck = async (checkType, stageType) => {
        const startTime = new Date();
        const employeeId = user.posid;

        const response = await fetch(`${apiBaseUrl}/api/protected/startCheck/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ employeeId, startTime, checkType, stageType }),
        });
        if (response.ok) {
            setShouldFetch(true);
        } else {
            console.error("Failed to update check start.");
        };
    };

    const handleEndCheck = async (checkType, stageType) => {
        const endTime = new Date();

        const response = await fetch(`${apiBaseUrl}/api/protected/endCheck/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ endTime, checkType, stageType }),
        });
        if (response.ok) {
            setShouldFetch(true);
        } else {
            console.error("Failed to update check end.");
        };
    };

    const handleNotApplicable = async (checkType, stageType) => {
        const employeeId = user.posid;

        const response = await fetch(`${apiBaseUrl}/api/protected/notApplicable/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ employeeId, checkType, stageType }),
        });
        if (response.ok) {
            setShouldFetch(true);
        } else {
            console.error("Failed to mark check not applicable.");
        };
    };

    const handleUndo = async (checkType, stageType) => {
        const employeeId = user.posid;

        const response = await fetch(`${apiBaseUrl}/api/protected/undoCheck/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ employeeId, checkType, stageType }),
        });
        if (response.ok) {
            setShouldFetch(true);
        } else {
            console.error("Failed to undo check.");
        };
    };

    const updateCheckStage = async (stage) => {
        setLoading(true);
        const employeeId = user.posid;

        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/updateCheckStage/${jobId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ stage, employeeId })
            });

            if (response.ok) {
                const data = await response.json();
                if (stage === 'Quality Check 2') {
                    const existingPdfBytes = await fetch(repairCompleteSlip).then(res => res.arrayBuffer());
                    const pdfDoc = await PDFDocument.load(existingPdfBytes);
                    const pages = pdfDoc.getPages();
                    const firstPage = pages[0];
                    const fontSize = 11;

                    let today = new Date();
                    const dd = String(today.getDate()).padStart(2, '0');
                    const mm = String(today.getMonth() + 1).padStart(2, '0');
                    const yyyy = today.getFullYear();
                    today = dd + '/' + mm + '/' + yyyy;
                    firstPage.drawText(data.data[0].name, {
                        x: 75,
                        y: 212,
                        size: fontSize,
                        color: rgb(0, 0, 0),
                    });
                    firstPage.drawText(data.data[0].contact, {
                        x: 75,
                        y: 197,
                        size: fontSize,
                        color: rgb(0, 0, 0),
                    });
                    firstPage.drawText(`${data.data[0].jobid}`, {
                        x: 75,
                        y: 181,
                        size: fontSize,
                        color: rgb(0, 0, 0),
                    });
                    firstPage.drawText(data.data[0].sku, {
                        x: 75,
                        y: 166,
                        size: fontSize,
                        color: rgb(0, 0, 0),
                    });
                    firstPage.drawText(data.data[0].partreceived, {
                        x: 75,
                        y: 149,
                        size: fontSize,
                        color: rgb(0, 0, 0),
                    });
                    firstPage.drawText(data.data[0].outletreceived, {
                        x: 75,
                        y: 133,
                        size: fontSize,
                        color: rgb(0, 0, 0),
                    });
                    firstPage.drawText(data.data[0].outletcollection, {
                        x: 75,
                        y: 117,
                        size: fontSize,
                        color: rgb(0, 0, 0),
                    });
                    firstPage.drawText(user.name, {
                        x: 75,
                        y: 100,
                        size: fontSize,
                        color: rgb(0, 0, 0),
                    });
                    firstPage.drawText(today, {
                        x: 75,
                        y: 85,
                        size: fontSize,
                        color: rgb(0, 0, 0),
                    });
                    const pdfBytes = await pdfDoc.save();
                    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
                    saveAs(blob, `Repair Completion Slip ${data.data[0].jobid}.pdf`);
                }
                setShouldFetch(true);
            } else {
                console.error("Failed to update stage.");
            }
        } catch (error) {
            console.error("Error updating stage: ", error);
        }
        setLoading(false);
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <header className='header'>
                <h2>Job ID: {jobId}</h2>
            </header>
            <div className='back'>
                <button type="button" onClick={back}>Back</button>
            </div>
            <div className='jobTable'>
                <div className='table-wrapper mobileScroll'>
                    <table className='fitTable'>
                        <thead className='head'>
                            <tr>
                                <th>SKU</th>
                                <th>Part Received</th>
                                <th>POS</th>
                                <th>Serial Number</th>
                                <th>Under Warranty</th>
                                <th>Created At</th>
                                <th>Outlet Received</th>
                                <th>Collection Outlet</th>
                                <th>Customer Name</th>
                                <th>Customer Contact</th>
                                <th>Customer Email</th>
                                <th>VIP</th>
                                <th>Job Status</th>
                                <th>Fault(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{jobs.sku}</td>
                                <td>{jobs.partreceived}</td>
                                <td>{jobs.pos}</td>
                                <td>{jobs.serialnumber}</td>
                                <td>{warranty}</td>
                                <td>{new Date(jobs.timestamp).toLocaleString('en-gb')}</td>
                                <td>{jobs.outletreceived}</td>
                                <td>{jobs.outletcollection}</td>
                                <td>{jobs.customername}</td>
                                <td>{jobs.customercontact}</td>
                                <td>{jobs.customeremail}</td>
                                <td>{jobs.vip === 1 ? 'True' : 'False'}</td>
                                <td>{jobs.stageofrepair}</td>
                                <td>{jobs.fault1}, {jobs.fault2}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='margin'>
                <div>
                    <h4>Customer Remarks:</h4>
                    <p>{jobs.remarks}</p>
                </div>
                {!loading && (
                    <div>
                        <h4>Outlet Images:</h4>
                        {imageCheck.length !== 0 && (
                            <div className='image-buttons'>
                                {jobs.imageUrls.map((imageUrl, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'black',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={() => handleImageNameClick(jobs.imageUrls[index])}
                                    >
                                        {`imageIndex-${index + 1}`}
                                    </button>
                                ))}
                            </div>
                        )}
                        <Modal
                            isOpen={showImageModal}
                            onRequestClose={() => setShowImageModal(false)}
                            style={customStyle}
                        >
                            <div className="modal-content">
                                <button onClick={() => setShowImageModal(false)} className="modal-close-btn">x</button>
                                <img className='newJobImage' src={selectedImage} alt="Selected" />
                            </div>
                        </Modal>
                    </div>
                )}

                {/* TO MAKE CHANGES */}
                {stage !== "Awaiting Delivery" && !loading && (
                    <div>
                        <h4>Assessment and Repair Details:</h4>
                        {!fEditMode && (
                            <div className='findingsDiv'>
                                <input className='findingsInput' type="text" value={findings} disabled />
                                {allowEditStages.includes(stage) && (
                                    <button style={{ marginLeft: '0.5rem' }} onClick={() => { setFEditMode(true) }}>Edit</button>
                                )}
                            </div>
                        )}
                        {fEditMode && (
                            <div className='findingsDiv'>
                                <input className='findingsInput' type="text" placeholder='Findings and actions taken' value={findings} onChange={e => setFindings(e.target.value)} maxLength={300} />
                                <button style={{ marginLeft: '0.5rem' }} onClick={() => { setFEditMode(false); handleFSave(); }}>Save</button>
                            </div>
                        )}
                        {parts && allowEditStages.includes(stage) && (
                            <div className='quantity'>
                                <div className='partsDiv'>
                                    <select className='partsSelect' value={selectedPart} onChange={e => setSelectedPart(e.target.value)}>
                                        <option value="">Select a part</option>
                                        {parts.map((part) => (
                                            <option key={part.partid} value={part.partid}>{`${part.partid}  —  ${part['GROUP(salesdescription)']}  —  $${part.unitprice}  —  Stock (Hysses): ${part['locationquantityonhand']}`}</option>
                                        ))}
                                    </select>
                                    <label style={{ marginTop: 0, whiteSpace: 'nowrap' }} className='label'>Available for Repair? </label>
                                    <input style={{}} type='checkbox' checked={inStock === 1} onChange={() => handleCheckboxChange(inStock)} />
                                </div>
                                <div className='quantityDiv'>
                                    <input type="number" value={quantity} min={1} onChange={e => setQuantity(Number(e.target.value) || 0)} placeholder="Quantity" required />
                                    {inStock === 1 && (<input type="number" value={quantitySalvaged} min={0} onChange={e => setQuantitySalvaged(Number(e.target.value) || 0)} placeholder="Quantity Salvaged" />)}
                                    {!jobs.warranty && stage === 'Received and Awaiting Assessment' && (<input type="number" value={quantityChargeable} min={0} onChange={e => setQuantityChargeable(Number(e.target.value) || 0)} placeholder="Quantity Chargeable" />)}
                                    <input type="text" value={partRemarks} onChange={e => setPartRemarks(e.target.value)} placeholder="Remarks" maxLength={300} />
                                    <button onClick={handleAddPart}>+</button>
                                    {alertMessage !== '' && (<p style={{ color: 'red' }}>{alertMessage}</p>)}
                                </div>
                            </div>
                        )}
                        <div style={{ marginLeft: 0, marginRight: 0, marginBottom: '0.5rem' }} className='table-wrapper mobileScroll'>
                            <table className='fitTable'>
                                <thead>
                                    <tr className='head'>
                                        <th>SKU</th>
                                        <th>Description</th>
                                        <th>Unit Price</th>
                                        <th>Quantity Required</th>
                                        <th>Quantity Salvaged</th>
                                        <th>Quantity Chargeable</th>
                                        <th>Stock (Hysses)</th>
                                        <th>Available for Repair?</th>
                                        <th>Total Price</th>
                                        <th>Remarks</th>
                                        {editMode && <th>Actions</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {addedParts.map((part, index) => (
                                        <tr key={index}>
                                            <td>{part.partid}</td>
                                            <td>{part['GROUP(salesdescription)']}</td>
                                            <td>{part?.unitprice ? part.unitprice : ''}</td>
                                            <td>{editMode ? <input type="number" value={part.quantity} min={1} onChange={e => handleQuantityChange(index, e.target.value, "quantity")} placeholder="Quantity" /> : part.quantity}</td>
                                            {part.instock === 1 && (
                                                <td>{editMode ? <input type="number" value={part.quantitysalvaged || 0} onChange={e => handleQuantityChange(index, e.target.value, "quantitysalvaged")} placeholder="Quantity" /> : (part.quantitysalvaged === null ? 0 : part.quantitysalvaged)}</td>
                                            )}
                                            {part.instock === 0 && (<td>0</td>)}
                                            {jobs.warranty === 0 && (
                                                <td>{editMode && stage === 'Received and Awaiting Assessment' ? <input type="number" value={part.quantitychargeable} onChange={e => handleQuantityChange(index, e.target.value, "quantitychargeable")} placeholder="Quantity" /> : (part.quantitychargeable === null ? 0 : part.quantitychargeable)}</td>
                                            )}
                                            {jobs.warranty === 1 && (<td>NA</td>)}
                                            <td>{part.locationquantityonhand}</td>
                                            <td>{editMode ? <input type='checkbox' checked={part.instock === 1} onChange={() => handleInStockChange(index, part.instock)} /> : (part.instock === 1 ? 'True' : 'False')}</td>
                                            <td>{parseFloat(part.totalPrice).toFixed(2)}</td>
                                            {!editMode && <td>{part.remarks}</td>}
                                            {editMode && <td><input type="text" value={part.remarks} onChange={e => handleRemarksChange(index, e.target.value)} placeholder='Remarks' maxLength={300} /></td>}
                                            {editMode && <td><button onClick={() => handleRemovePart(index)}>Remove</button></td>}
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="8">Total Net Price</td>
                                        <td>{addedParts.reduce((acc, part) => acc + parseFloat(part.totalPrice), 0).toFixed(2)}</td>
                                    </tr>
                                    {jobs.vip === 1 && (
                                        <tr>
                                            <td colSpan="8">Total VIP Price</td>
                                            <td>{((addedParts.reduce((acc, part) => acc + parseFloat(part.totalPrice), 0)) * 0.9).toFixed(2)}</td>
                                        </tr>
                                    )}
                                </tfoot>
                            </table>
                        </div>
                        {!editMode && allowEditStages.includes(stage) && (
                            <button className='mobileButton' onClick={() => setEditMode(true)} style={{ marginRight: '0.5rem' }}>Edit</button>
                        )}
                        {editMode && allowEditStages.includes(stage) && (
                            <button className='mobileButton' onClick={() => { setEditMode(false); handleSave(); }}>Save</button>
                        )}
                        {!assessmentComplete && findings && !fEditMode && !editMode && (
                            <button className='mobileButton' onClick={() => setShowAssessmentModal(true)}>Finish Assessment</button>
                        )}
                    </div>
                )}
                {/* TO MAKE CHANGES */}

                {stage === 'Repair Process' && (
                    <div>
                        <button onClick={() => setShowRepairModal(true)} style={{ marginTop: '0.5rem' }}>Mark Repair Process as Complete</button>
                    </div>
                )}
                <Modal
                    isOpen={showAssessmentModal}
                    onRequestClose={() => setShowAssessmentModal(false)}
                    style={customStyle}
                >
                    <div className="modal-content">
                        <button onClick={() => setShowAssessmentModal(false)} className="modal-close-btn">x</button>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <h3>Confirm assessment completed?</h3>
                        </div>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <p>Stock will be updated. This action cannot be undone.</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button onClick={() => { handleFinishAssessment(); setShowAssessmentModal(false); }}>Confirm</button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={showRepairModal}
                    onRequestClose={() => setShowRepairModal(false)}
                    style={customStyle}
                >
                    <div className="modal-content">
                        <button onClick={() => setShowRepairModal(false)} className="modal-close-btn">x</button>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <h3>Confirm repair completed?</h3>
                        </div>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <p>This action cannot be undone.</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button onClick={() => { completeRepair(); setShowRepairModal(false); }}>Confirm</button>
                        </div>
                    </div>
                </Modal>
                {stage === 'Waiting for Spare Parts' && (
                    <div style={{ marginTop: '0.5rem' }}>
                        <button onClick={() => setShowAvailableModal(true)}>Spare Parts Available</button>
                    </div>
                )}
                <Modal
                    isOpen={showAvailableModal}
                    onRequestClose={() => setShowAvailableModal(false)}
                    style={customStyle}
                >
                    <div className="modal-content">
                        <button onClick={() => setShowAvailableModal(false)} className="modal-close-btn">x</button>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <h3>Confirm spare parts available?</h3>
                        </div>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <p>This action cannot be undone.</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button onClick={() => { handlePartsAvailable(); setShowAvailableModal(false); }}>Confirm</button>
                        </div>
                    </div>
                </Modal>
                {stage === '24 Hour Test 1' && (
                    <div>
                        <h4>24 Hour Test 1:</h4>
                        {!testFinish && !testStart && (<button className='mobileButton' onClick={() => handleStartTest('24 Hour Test 1')}>Start</button>)}
                        {!testFinish && testStart && (<p>Started at: {new Date(testStart).toLocaleString('en-gb')}</p>)}
                        {!testFinish && testStart && (<button onClick={() => { setShowRestart1Modal(true) }} style={{ marginRight: '0.5rem' }}>Restart Test</button>)}
                        {!testFinish && testStart && isMoreThan24HoursAgo(testStart) && (<button className='mobileButton' onClick={() => setShowTest1Modal(true)} style={{ marginRight: '1rem' }}>Finish</button>)}
                        {testFinish && testStart && (<p>Complete {new Date(testFinish).toLocaleString('en-gb')}</p>)}
                    </div>
                )}
                <Modal
                    isOpen={showTest1Modal}
                    onRequestClose={() => setShowTest1Modal(false)}
                    style={customStyle}
                >
                    <div className="modal-content">
                        <button onClick={() => setShowTest1Modal(false)} className="modal-close-btn">x</button>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <h3>Confirm test completed?</h3>
                        </div>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <p>This action cannot be undone.</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button onClick={() => { handleFinishTest('24 Hour Test 1'); setShowTest1Modal(false); }}>Confirm</button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={showRestart1Modal}
                    onRequestClose={() => setShowRestart1Modal(false)}
                    style={customStyle}
                >
                    <div className="modal-content">
                        <button onClick={() => setShowRestart1Modal(false)} className="modal-close-btn">x</button>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <h3>Confirm restart test?</h3>
                        </div>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <p>This action cannot be undone.</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button onClick={() => { handleRestartTest('24 Hour Test 1'); setShowRestart1Modal(false); }}>Confirm</button>
                        </div>
                    </div>
                </Modal>
                {stage === 'Quality Check 1' && (
                    <div style={{ marginLeft: 0, marginRight: 0, marginTop: '1rem' }} className='table-wrapper mobileScroll'>
                        <table className='fitTable'>
                            <thead>
                                <tr className='head'>
                                    <th>Check Type</th>
                                    <th>Action / Status</th>
                                    <th>NA</th>
                                    <th>Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayChecks.map((check) => (
                                    <tr key={check.checkType}>
                                        <td>{check.checkType}</td>
                                        {check.applicable ?
                                            (check.endTime ?
                                                <td>Complete {new Date(check.endTime).toLocaleString('en-gb')}</td> :
                                                <td>{check.startTime ?
                                                    <button onClick={() => handleEndCheck(check.checkType, 'Quality Check 1')}>Confirm Working</button> :
                                                    <button onClick={() => handleStartCheck(check.checkType, 'Quality Check 1')}>Start Check</button>}
                                                </td>
                                            ) : <td>Not Applicable</td>}
                                        <td>{check.applicable && !check.startTime ? <button onClick={() => handleNotApplicable(check.checkType, 'Quality Check 1')}>Set NA</button> : '-'}</td>
                                        <td><button onClick={() => handleUndo(check.checkType, 'Quality Check 1')}>Restart Check</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {stage === 'Quality Check 1' && QC1Complete && (
                    <div>
                        <button onClick={() => updateCheckStage('Quality Check 1')} style={{ marginTop: '0.5rem' }}>Mark Quality Check 1 Complete</button>
                    </div>
                )}
                {stage === '24 Hour Test 2' && (
                    <div>
                        <h4>24 Hour Test 2:</h4>
                        {!test2Finish && !test2Start && (<button className='mobileButton' onClick={() => handleStartTest('24 Hour Test 2')}>Start</button>)}
                        {!test2Finish && test2Start && (<p>Started at: {new Date(test2Start).toLocaleString('en-gb')}</p>)}
                        {!test2Finish && test2Start && (<button onClick={() => { setShowRestart2Modal(true) }} style={{ marginRight: '0.5rem' }}>Restart Test</button>)}
                        {!test2Finish && test2Start && isMoreThan24HoursAgo(test2Start) && (<button className='mobileButton' onClick={() => setShowTest2Modal(true)}>Finish</button>)}
                        {test2Finish && test2Start && (<p>Complete {new Date(test2Finish).toLocaleString('en-gb')}</p>)}
                    </div>
                )}
                <Modal
                    isOpen={showTest2Modal}
                    onRequestClose={() => setShowTest2Modal(false)}
                    style={customStyle}
                >
                    <div className="modal-content">
                        <button onClick={() => setShowTest2Modal(false)} className="modal-close-btn">x</button>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <h3>Confirm test 2 completed?</h3>
                        </div>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <p>This action cannot be undone.</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button onClick={() => { handleFinishTest('24 Hour Test 2'); setShowTest2Modal(false); }}>Confirm</button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={showRestart2Modal}
                    onRequestClose={() => setShowRestart2Modal(false)}
                    style={customStyle}
                >
                    <div className="modal-content">
                        <button onClick={() => setShowRestart2Modal(false)} className="modal-close-btn">x</button>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <h3>Confirm restart test?</h3>
                        </div>
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                            <p>This action cannot be undone.</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button onClick={() => { handleRestartTest('24 Hour Test 2'); setShowRestart2Modal(false); }}>Confirm</button>
                        </div>
                    </div>
                </Modal>
                {stage === 'Quality Check 2' && (
                    <div style={{ marginLeft: 0, marginRight: 0, marginTop: '1rem' }} className='table-wrapper mobileScroll'>
                        <table className='fitTable'>
                            <thead>
                                <tr className='head'>
                                    <th>Check Type</th>
                                    <th>Action / Status</th>
                                    <th>NA</th>
                                    <th>Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayChecks2.map((check) => (
                                    <tr key={check.checkType}>
                                        <td>{check.checkType}</td>
                                        {check.applicable ?
                                            (check.endTime ?
                                                <td>Complete {new Date(check.endTime).toLocaleString('en-gb')}</td> :
                                                <td>{check.startTime ?
                                                    <button onClick={() => handleEndCheck(check.checkType, 'Quality Check 2')}>Confirm Working</button> :
                                                    <button onClick={() => handleStartCheck(check.checkType, 'Quality Check 2')}>Start Check</button>}
                                                </td>
                                            ) : <td>Not Applicable</td>}
                                        <td>{check.applicable && !check.startTime ? <button onClick={() => handleNotApplicable(check.checkType, 'Quality Check 2')}>Set NA</button> : '-'}</td>
                                        <td><button onClick={() => handleUndo(check.checkType, 'Quality Check 2')}>Restart Check</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {stage === 'Quality Check 2' && QC2Complete && (
                    <div>
                        <button onClick={() => updateCheckStage('Quality Check 2')} style={{ marginTop: '0.5rem' }}>Mark Quality Check 2 Complete</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RepairDetails;