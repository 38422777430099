import React, { useState, useEffect, useCallback, useContext } from 'react';
import Modal from 'react-modal';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { UserContext } from './context';
import './assets/styles/repair.styles.css'

const { hostname } = window.location;
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

Modal.setAppElement('#root');

const StyledPopover = styled(Popover)(({ theme }) => ({
    pointerEvents: 'none',
    boxShadow: 'none',
    '& .MuiPopover-paper': {
        boxShadow: 'none',
        border: '1px solid black',
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const JobDetails = () => {
    const { accessToken } = useContext(UserContext);
    const navigate = useNavigate();

    let { jobId } = useParams();

    const [jobs, setJobs] = useState([]);
    const [warranty, setWarranty] = useState('');
    const [loading, setLoading] = useState(true);

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const sortField = queryParams.get('sort') || '';
    const sortOrder = queryParams.get('order') || 'asc';
    const stageFilter = queryParams.get('stage') || '';
    const typeFilter = queryParams.get('type') || '';
    const contactFilter = queryParams.get('contact') || '';
    const dateStartFilter = queryParams.get('dateStart') || '';
    const dateEndFilter = queryParams.get('dateEnd') || '';
    const skuFilter = queryParams.get('sku') || '';
    const partReceivedFilter = queryParams.get('part') || '';
    const page = queryParams.get('page') || 1;

    const [filters,] = useState({
        stage: stageFilter,
        type: typeFilter,
        contact: contactFilter,
        dateStart: dateStartFilter,
        dateEnd: dateEndFilter,
        sku: skuFilter,
        part: partReceivedFilter,
        sort: sortField,
        order: sortOrder,
        page: page
    });

    const [imageCheck, setImageCheck] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState('');
    const [findings, setFindings] = useState('');
    const [addedParts, setAddedParts] = useState([]);
    const [shouldFetch, setShouldFetch] = useState(false);
    const [parts, setParts] = useState(null);

    const [salesOrder, setSalesOrder] = useState('');
    const [taxInvoice, setTaxInvoice] = useState('');
    const [checks, setChecks] = useState([]);

    const back = () => {
        const { stage, type, contact, dateStart, dateEnd, sku, part, sort, order, page } = filters;
        navigate(`/jobStatus?stage=${stage}&type=${type}&contact=${contact}&dateStart=${dateStart}&dateEnd=${dateEnd}&sku=${sku}&part=${part}&sort=${sort}&order=${order}&page=${page}`);
    };

    const inventoryData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/parts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ apiBaseUrl })
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();
            const modifiedParts = responseBody.results.map((part, index) => ({
                ...part.values,
                partid: part.values['GROUP(itemid)'].split(':')[1].trim(),
                index: index + 1,
                locationquantityonhand: Number(part.values['SUM(locationquantityonhand)']),
                unitprice: Number(part.values['SUM(baseprice)'] * 1.09).toFixed(2)
            }));
            setParts(modifiedParts);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/jobStatus/${jobId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const imageUrls = data.images.map(imageData => {
                const uint8Array = new Uint8Array(imageData.imagedata.data);
                const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                const imageUrl = URL.createObjectURL(blob);
                return imageUrl;
            });

            const stages = data.stages.map(stage => ({
                ...stage,
                stagedata: JSON.parse(stage.stagedata || '{}')
            }));

            setJobs({ ...data, imageUrls, stages });
        } catch (error) {
            console.error("Failed to fetch job details:", error);
        } finally {
            setLoading(false);
        }
    }, [jobId, accessToken]);

    useEffect(() => {
        inventoryData();
        fetchData();
    }, [jobId, fetchData, inventoryData]);

    useEffect(() => {
        if (jobs.warranty === 1) {
            setWarranty('True');
        } else {
            setWarranty('False');
        };
        if (jobs.charges?.length > 0) {
            const chargesData = jobs.charges[0];
            setSalesOrder(chargesData.salesorder || '');
            setTaxInvoice(chargesData.taxinvoice || '');
        }
        if (jobs.assessment) {
            setFindings(jobs.assessment.findings || '');
        };
        if (jobs.repairParts && parts) {
            const mappedParts = jobs.repairParts.map(repairPart => {
                const partDetails = parts.find(part => part.partid === repairPart.partid);
                return {
                    ...partDetails,
                    quantity: repairPart.quantity,
                    quantitysalvaged: repairPart.quantitysalvaged,
                    quantitychargeable: repairPart.quantitychargeable,
                    totalPrice: partDetails.unitprice * repairPart.quantitychargeable,
                    remarks: repairPart.remarks,
                    instock: repairPart.instock
                };
            });
            setAddedParts(mappedParts);
        };
        if (jobs.checks) {
            setChecks(jobs.checks);
        };
        if (jobs.imageUrls) {
            setImageCheck(jobs.imageUrls);
        }
    }, [jobs, parts]);

    useEffect(() => {
        if (shouldFetch) {
            fetchData();
            setShouldFetch(false);
        };
    }, [shouldFetch, fetchData]);

    const handleImageNameClick = (image) => {
        setSelectedImage(image);
        setShowImageModal(true);
    };

    const handlePopoverOpen = (event, value) => {
        if (/^\d{4,7}$/.test(value)) {
            setAnchorEl(event.currentTarget);
            fetchPopoverData(value);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const fetchPopoverData = async (value) => {
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/employeeName`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ apiBaseUrl, value })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setPopoverContent(data);
        } catch (error) {
            console.error("Failed to fetch popover data:", error);
            setPopoverContent('Error fetching name');
        }
    };

    const customStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <header className='header'>
                <h2>Job ID: {jobId}</h2>
            </header>
            <div className='back'>
                <button type="button" onClick={back}>Back</button>
            </div>

            <div className='jobTable table-wrapper mobileScroll'>
                <table className='fitTable'>
                    <thead>
                        <tr className='head'>
                            <th>SKU</th>
                            <th>Part Received</th>
                            <th>POS</th>
                            <th>Serial Number</th>
                            <th>Under Warranty</th>
                            {jobs.assessmentfeetaxinvoice && (<th>Assessment Fee Tax Invoice</th>)}
                            <th>Created At</th>
                            <th>Outlet Received</th>
                            <th>Outlet Collection</th>
                            <th>Customer Name</th>
                            <th>Customer Contact</th>
                            <th>Customer Email</th>
                            <th>VIP</th>
                            <th>Job Status</th>
                            <th>Fault(s)</th>
                            <th>Root Job Id</th>
                            <th>Completed At</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{jobs.sku}</td>
                            <td>{jobs.partreceived}</td>
                            <td>{jobs.pos}</td>
                            <td>{jobs.serialnumber}</td>
                            <td>{warranty}</td>
                            {jobs.assessmentfeetaxinvoice && (<td>{jobs.assessmentfeetaxinvoice}</td>)}
                            <td>{new Date(jobs.timestamp).toLocaleString('en-gb')}</td>
                            <td>{jobs.outletreceived}</td>
                            <td>{jobs.outletcollection}</td>
                            <td>{jobs.customername}</td>
                            <td>{jobs.customercontact}</td>
                            <td>{jobs.customeremail}</td>
                            <td>{jobs.vip === 1 ? 'True' : 'False'}</td>
                            <td>{jobs.stageofrepair}</td>
                            <td>{jobs.fault1}, {jobs.fault2}</td>
                            <td>{jobs.rootjobid}</td>
                            <td>{jobs.completetimestamp ? new Date(jobs.completetimestamp).toLocaleString('en-gb') : 'NA'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='margin'>

                <div>
                    <h4>Customer Remarks:</h4>
                    <p>{jobs.remarks}</p>
                </div>

                {!loading && (
                    <div>
                        <h4>Outlet Images:</h4>
                        {imageCheck.length !== 0 && (
                            <div className='image-buttons'>
                                {jobs.imageUrls.map((imageUrl, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'black',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={() => handleImageNameClick(jobs.imageUrls[index])}
                                    >
                                        {`imageIndex-${index + 1}`}
                                    </button>
                                ))}
                            </div>
                        )}
                        <Modal
                            isOpen={showImageModal}
                            onRequestClose={() => setShowImageModal(false)}
                            style={customStyle}
                        >
                            <div className="modal-content">
                                <button onClick={() => setShowImageModal(false)} className="modal-close-btn">x</button>
                                <img className='newJobImage' src={selectedImage} alt="Selected" />
                            </div>
                        </Modal>
                    </div>
                )}

                {!loading && (
                    <div>
                        <div>
                            <h4>Assessment and Repair Details:</h4>
                            <div>
                                <p>{findings}</p>
                            </div>
                            <div style={{ marginLeft: 0, marginRight: 0 }} className='table-wrapper mobileScroll'>
                                <table className='fitTable'>
                                    <thead>
                                        <tr className='head'>
                                            <th>SKU</th>
                                            <th>Description</th>
                                            <th>Unit Price</th>
                                            <th>Quantity Required</th>
                                            <th>Quantity Salvaged</th>
                                            <th>Quantity Chargeable</th>
                                            <th>Stock (Hysses)</th>
                                            <th>Available for Repair?</th>
                                            <th>Total Price</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {addedParts.map((part, index) => (
                                            <tr key={index}>
                                                <td>{part.partid}</td>
                                                <td>{part['GROUP(salesdescription)']}</td>
                                                <td>{part?.unitprice ? part.unitprice : ''}</td>
                                                <td>{part.quantity}</td>
                                                <td>{part.quantitysalvaged}</td>
                                                {jobs.warranty === 0 && (
                                                    <td>{part.quantitychargeable === null ? '0' : part.quantitychargeable}</td>
                                                )}
                                                {jobs.warranty === 1 && (<td>NA</td>)}
                                                <td>{part.locationquantityonhand}</td>
                                                <td>{part.instock === 1 ? 'True' : 'False'}</td>
                                                <td>{parseFloat(part.totalPrice).toFixed(2)}</td>
                                                <td>{part.remarks}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="8">Total Net Price</td>
                                            <td>{addedParts.reduce((acc, part) => acc + parseFloat(part.totalPrice), 0).toFixed(2)}</td>
                                        </tr>
                                        {jobs.vip === 1 && (
                                            <tr>
                                                <td colSpan="8">Total VIP Price</td>
                                                <td>{((addedParts.reduce((acc, part) => acc + parseFloat(part.totalPrice), 0)) * 0.9).toFixed(2)}</td>
                                            </tr>
                                        )}
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                )}

                {jobs.warranty === 0 && !loading && (
                    <div style={{ marginTop: '1rem' }}>
                        <h4>Accounting Details:</h4>
                        <div style={{ marginLeft: 0, marginRight: 0 }} className='table-wrapper mobileScroll'>
                            <table className='fitTable'>
                                <thead>
                                    <tr className='head'>
                                        <th>Items</th>
                                        <th>Reference No.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Hysses Sales Order</td>
                                        <td>{salesOrder}</td>
                                    </tr>
                                    <tr>
                                        <td>Hysses Tax Invoice</td>
                                        <td>{taxInvoice}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {!loading && checks.length > 0 && (
                    <div style={{ marginTop: '1rem' }}>
                        <h4>Check Details:</h4>
                        <div style={{ marginLeft: 0, marginRight: 0 }} className='table-wrapper mobileScroll'>
                            <table className='fitTable'>
                                <thead>
                                    <tr className='head'>
                                        <th>Stage</th>
                                        <th>Check</th>
                                        <th>Applicable?</th>
                                        <th>Start</th>
                                        <th>End</th>
                                        <th>Duration</th>
                                        <th>Employee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {checks.map((check) => {
                                        const startTime = check.starttime ? new Date(check.starttime) : null;
                                        const endTime = check.endtime ? new Date(check.endtime) : null;
                                        const duration = startTime && endTime ? endTime - startTime : null;

                                        const formatDuration = (duration) => {
                                            if (!duration) return 'NA';
                                            const milliseconds = duration
                                            const seconds = Math.floor(milliseconds / 1000);
                                            const minutes = Math.floor(seconds / 60);
                                            const hours = Math.floor(minutes / 60);
                                            const days = Math.floor(hours / 24);

                                            const remainingHours = hours % 24;
                                            const remainingMinutes = minutes % 60;
                                            const remainingSeconds = seconds % 60;
                                            const remainingMilliseconds = milliseconds % 1000;

                                            const durationParts = [];
                                            if (days > 0) durationParts.push(`${days}d`);
                                            if (remainingHours > 0) durationParts.push(`${remainingHours}h`);
                                            if (remainingMinutes > 0) durationParts.push(`${remainingMinutes}m`);
                                            if (remainingSeconds > 0 || remainingMilliseconds > 0) {
                                                const secondsString = remainingMilliseconds > 0
                                                    ? `${remainingSeconds}.${remainingMilliseconds.toString().padStart(3, '0')}s`
                                                    : `${remainingSeconds}s`;
                                                durationParts.push(secondsString);
                                            }

                                            return durationParts.join(' ');
                                        };

                                        return (
                                            <tr key={check.checkid}>
                                                <td>{check.stagetype}</td>
                                                <td>{check.checktype}</td>
                                                <td>{check.applicable ? 'True' : 'False'}</td>
                                                <td>{startTime ? startTime.toLocaleString('en-gb') : 'NA'}</td>
                                                <td>{endTime ? endTime.toLocaleString('en-gb') : 'NA'}</td>
                                                <td>{formatDuration(duration)}</td>
                                                <td>
                                                    <p
                                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                                        aria-haspopup="true"
                                                        onMouseEnter={(event) => handlePopoverOpen(event, check.employeeid)}
                                                        onMouseLeave={handlePopoverClose}
                                                        style={{ margin: 0 }}
                                                    >
                                                        {check.employeeid}
                                                    </p>
                                                    <StyledPopover
                                                        id="mouse-over-popover"
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        onClose={handlePopoverClose}
                                                        disableRestoreFocus
                                                    >
                                                        <StyledTypography>{popoverContent}</StyledTypography>
                                                    </StyledPopover>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {!loading && (
                    <div style={{ marginTop: '1rem' }}>
                        <h4>Job History: </h4>
                        {jobs.stages && jobs.stages.length > 0 && (
                            <div>
                                {jobs.stages.map((stage, index) => (
                                    <div key={index} style={{ marginBottom: '1rem' }}>
                                        <label className='label'>{stage.stagetype}:</label>
                                        <div style={{ marginLeft: 0, marginRight: 0 }} className='table-wrapper mobileScroll'>
                                            <table className='fitTable'>
                                                <thead>
                                                    <tr className='head'>
                                                        <th>Created At</th>
                                                        {Object.keys(stage.stagedata).map((key) => (
                                                            <th key={key}>{key}</th>
                                                        ))}
                                                        <th>Completed At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{new Date(stage.createdat).toLocaleString('en-gb')}</td>
                                                        {Object.values(stage.stagedata).map((value, index) => (
                                                            <td key={index}>
                                                                <p
                                                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                                                    aria-haspopup="true"
                                                                    onMouseEnter={(event) => handlePopoverOpen(event, value)}
                                                                    onMouseLeave={handlePopoverClose}
                                                                    style={{ margin: 0 }}
                                                                >
                                                                    {/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value) ? new Date(value).toLocaleString('en-gb') : value}
                                                                </p>
                                                                <StyledPopover
                                                                    id="mouse-over-popover"
                                                                    open={open}
                                                                    anchorEl={anchorEl}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    onClose={handlePopoverClose}
                                                                    disableRestoreFocus
                                                                >
                                                                    <StyledTypography className="typographyPadding">{popoverContent}</StyledTypography>
                                                                </StyledPopover>
                                                            </td>
                                                        ))}
                                                        <td>{stage.completedat ? new Date(stage.completedat).toLocaleString('en-gb') : 'NA'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}

            </div>
        </div >
    );
};

export default JobDetails;