import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const { hostname } = window.location
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

export const UserContext = createContext();

const api = axios.create({
    baseURL: apiBaseUrl,
    withCredentials: true
});

export const UserProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [accessiblePages, setAccessiblePages] = useState([]);

    useEffect(() => {
        const requestInterceptor = api.interceptors.request.use(config => {
            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            }
            return config;
        }, error => {
            return Promise.reject(error);
        });

        const responseInterceptor = api.interceptors.response.use(response => response, async error => {
            const originalRequest = error.config;
            if (error.response.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    const response = await api.post('/api/refresh-token');
                    const { accessToken } = response.data;
                    setAccessToken(accessToken);
                    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                    originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                    return api(originalRequest);
                } catch (refreshError) {
                    setUser(null);
                    setAccessToken(null);
                    setAccessiblePages(null);
                    setLoading(false);
                    return Promise.reject(refreshError);
                }
            }
            return Promise.reject(error);
        });

        return () => {
            api.interceptors.request.eject(requestInterceptor);
            api.interceptors.response.eject(responseInterceptor);
        };
    }, [accessToken]);

    const refreshAccessToken = async () => {
        if (window.location.pathname === '/') {
            setLoading(false);
            return;
        }
        try {
            const response = await api.post('/api/refresh-token');
            if (response.status === 200) {
                const { user, accessToken, accessiblePages } = response.data;
                setAccessToken(accessToken);
                setUser(user);
                setAccessiblePages(accessiblePages);
                setLoading(false);
            } else if (response.status === 401) {
                setUser(null);
                setAccessToken(null);
                setAccessiblePages(null);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error refreshing access token:', error);
            setUser(null);
            setAccessToken(null);
            setAccessiblePages(null);
            setLoading(false);
        };
    };

    useEffect(() => {
        refreshAccessToken();
        const interval = setInterval(refreshAccessToken, 10 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    const removeUser = () => {
        setUser(null);
        setAccessToken(null);
        setAccessiblePages(null);
        setLoading(false);
        api.post('/api/logout')
            .then(() => {
                window.location.href = '/';
            })
            .catch(error => {
                console.error("Logout failed:", error);
            });
    };

    return (
        <UserContext.Provider value={{ user, setUser, accessToken, setAccessToken, loading, removeUser, accessiblePages, setAccessiblePages }}>
            {children}
        </UserContext.Provider>
    );
};