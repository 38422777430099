import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate, Link, Navigate, useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { UserContext } from './context';
import './assets/styles/repair.styles.css'

const { hostname } = window.location;
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

export default function RepairQC() {
    const { user, accessToken, accessiblePages } = useContext(UserContext);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const [sortField, setSortField] = useState(queryParams.get('sort') || '');
    const [sortOrder, setSortOrder] = useState(queryParams.get('order') || 'asc');

    const [jobs, setJobs] = useState([]);
    const [checkedJobs, setCheckedJobs] = useState({});
    const [shouldFetch, setShouldFetch] = useState(false);

    const [currentPage, setCurrentPage] = useState(queryParams.get('page') || 1);;
    const pageSize = 20;
    const [totalCount, setTotalCount] = useState(0);
    const totalPages = Math.ceil(totalCount / pageSize);
    const [searchPage, setSearchPage] = useState('');

    const back = () => {
        navigate('/dashboard');
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        const params = new URLSearchParams({
            page: currentPage,
            limit: pageSize,
            sortField: sortField,
            sortOrder: sortOrder
        });

        const response = await fetch(`${apiBaseUrl}/api/protected/repairQC?${params}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        const data = await response.json();
        setJobs(data.data);
        setTotalCount(data.totalCount);
        setLoading(false);
    }, [currentPage, pageSize, sortField, sortOrder, accessToken]);

    useEffect(() => {
        if (accessiblePages.includes('repairQC')) {
            fetchData();
        };
    }, [fetchData, accessiblePages, shouldFetch]);

    if (!accessiblePages.includes('repairQC')) {
        return <Navigate to="/dashboard" />;
    };

    const sortData = (field) => {
        const order = sortField === field && sortOrder === 'desc' ? 'asc' : 'desc';
        setSortField(field);
        setSortOrder(order);
    };

    const handleCheckboxChange = (jobId) => {
        setCheckedJobs(prev => ({ ...prev, [jobId]: !prev[jobId] }));
    };

    const confirm = async () => {
        setLoading(true);
        if (!checkedJobs) {
            return;
        };
        const checkedJobIds = Object.keys(checkedJobs).filter(jobId => checkedJobs[jobId]);
        const employeeId = user.posid

        const response = await fetch(`${apiBaseUrl}/api/protected/updateArrival`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ jobIds: checkedJobIds, employeeId: employeeId })
        });

        if (response.ok) {
            const responseData = await response.json();
            setShouldFetch(prev => !prev);
            const optimisticUpdate = jobs.map(job => {
                if (checkedJobs[job.jobid]) {
                    return { ...job, arrivaltimestamp: responseData.timestamp };
                }
                return job;
            });
            setJobs(optimisticUpdate);
        } else {
            console.error("Error updating timestamps.");
        }
        setLoading(false);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        };
    };

    const renderPageButtons = () => {
        const pageButtons = [];

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageButtons.push(
                    <button
                        key={i}
                        onClick={() => handlePageChange(i)}
                        disabled={currentPage === i}
                        style={{
                            backgroundColor: currentPage === i ? '#333' : 'black',
                            border: currentPage === i ? '1.5px solid #333' : 'black'
                        }}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            if (currentPage <= 3) {
                for (let i = 1; i <= 5; i++) {
                    pageButtons.push(
                        <button
                            key={i}
                            onClick={() => handlePageChange(i)}
                            disabled={currentPage === i}
                            style={{
                                backgroundColor: currentPage === i ? '#333' : 'black',
                                border: currentPage === i ? '1.5px solid #333' : 'black'
                            }}
                        >
                            {i}
                        </button>
                    );
                }
                pageButtons.push(<span key="ellipsis1">...</span>);
                pageButtons.push(
                    <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
                        {totalPages}
                    </button>
                );
            } else if (currentPage >= totalPages - 2) {
                pageButtons.push(
                    <button key={1} onClick={() => handlePageChange(1)}>
                        1
                    </button>
                );
                pageButtons.push(<span key="ellipsis2">...</span>);
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    pageButtons.push(
                        <button
                            key={i}
                            onClick={() => handlePageChange(i)}
                            disabled={currentPage === i}
                            style={{
                                backgroundColor: currentPage === i ? '#333' : 'black',
                                border: currentPage === i ? '1.5px solid #333' : 'black'
                            }}
                        >
                            {i}
                        </button>
                    );
                }
            } else {
                pageButtons.push(
                    <button key={1} onClick={() => handlePageChange(1)}>
                        1
                    </button>
                );
                pageButtons.push(<span key="ellipsis3">...</span>);
                for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                    pageButtons.push(
                        <button
                            key={i}
                            onClick={() => handlePageChange(i)}
                            disabled={currentPage === i}
                            style={{
                                backgroundColor: currentPage === i ? '#333' : 'black',
                                border: currentPage === i ? '1.5px solid #333' : 'black'
                            }}
                        >
                            {i}
                        </button>
                    );
                }
                pageButtons.push(<span key="ellipsis4">...</span>);
                pageButtons.push(
                    <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
                        {totalPages}
                    </button>
                );
            }
        }

        return pageButtons;
    };

    const handleSearchPageChange = (e) => {
        setSearchPage(e.target.value);
    };

    const handleGoToPage = () => {
        const page = parseInt(searchPage);
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            setSearchPage(page);
        }
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <header className='header'>
                <h1>Repair and Quality Check</h1>
            </header>
            <div className='back spaceBetween'>
                <button type="button" onClick={back}>Back</button>
                <button type="button" onClick={confirm}>Confirm Arrival</button>
            </div>
            <div>
                <div className='table-wrapper mobileScroll'>
                    <table className='fitTable'>
                        <thead className='head'>
                            <tr>
                                <th className='sort' onClick={() => sortData('jobid')}>Job Id {sortField === 'jobid' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('sku')}>SKU {sortField === 'sku' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th>Part Received</th>
                                <th>POS</th>
                                <th className='sort' onClick={() => sortData('timestamp')}>Created At {sortField === 'timestamp' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('customername')}>Customer Name {sortField === 'customername' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th>Customer Contact</th>
                                <th className='sort' onClick={() => sortData('stageofrepair')}>Job Status {sortField === 'stageofrepair' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('arrivaltimestamp')}>Arrived at HQ {sortField === 'arrivaltimestamp' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobs.map((job) => (
                                <tr key={job.jobid}>
                                    <td>
                                        <Link to={`/repairQC/${job.jobid}?sort=${sortField}&order=${sortOrder}&page=${currentPage}`}>
                                            {job.jobid}
                                        </Link>
                                    </td>
                                    <td>{job.sku}</td>
                                    <td>{job.partreceived}</td>
                                    <td>{job.pos}</td>
                                    <td>{new Date(job.timestamp).toLocaleString('en-gb')}</td>
                                    <td>{job.customername}</td>
                                    <td>{job.customercontact}</td>
                                    <td>{job.stageofrepair}</td>
                                    <td>
                                        {job.arrivaltimestamp ? new Date(job.arrivaltimestamp).toLocaleString('en-gb') : (
                                            <input
                                                type='checkbox'
                                                onChange={() => handleCheckboxChange(job.jobid)}
                                                checked={!!checkedJobs[job.jobid]}
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='pagination'>
                    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            style={{ marginRight: '1rem' }}
                        >
                            &lt;
                        </button>
                        {renderPageButtons()}
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            style={{ marginLeft: '1rem' }}
                        >
                            &gt;
                        </button>
                    </div>
                    <div style={{ marginBottom: '1rem' }}>
                        <label style={{ marginRight: '1rem' }}>Go to page:</label>
                        <input
                            type="number"
                            min={1}
                            max={totalPages}
                            value={searchPage}
                            onChange={handleSearchPageChange}
                        />
                        <button style={{ marginLeft: '1rem' }} onClick={handleGoToPage}>Go</button>
                    </div>
                </div>
            </div>
        </div>
    );
};