import React, { useContext, useEffect, useState, useCallback } from 'react';
import Modal from 'react-modal';
import { useNavigate, Navigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { UserContext } from './context';
import './newJob.css'
import './assets/styles/general.styles.css';

Modal.setAppElement('#root');

const { hostname } = window.location;
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

export default function RegisterWarranty() {
    const { user, accessToken, accessiblePages } = useContext(UserContext);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [devices, setDevices] = useState(null);
    const [outlets, setOutlets] = useState(null);

    const back = () => {
        navigate('/dashboard');
    };

    const devicesData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/devices`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ apiBaseUrl })
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();
            setDevices(responseBody);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    const outletsData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/outlets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ apiBaseUrl })
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();
            setOutlets(responseBody);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    useEffect(() => {
        if (accessiblePages.includes('registerWarranty')) {
            devicesData();
            outletsData();
        };
    }, [accessiblePages, devicesData, outletsData]);

    if (!accessiblePages.includes('registerWarranty')) {
        return <Navigate to="/dashboard" />;
    };

    const handleInputChange = (e) => {
        const { name, value, maxLength } = e.target;
        if (value.length > maxLength) {
            e.target.value = value.slice(0, maxLength);
        }
        if (name === 'customerName') {
            e.target.value = value.toUpperCase();
        }
        if (name === 'customerEmail') {
            e.target.value = value.toLowerCase();
        }
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData();

        formData.append('employeeId', user.posid);
        formData.append('customerName', event.target.elements.customerName.value);
        formData.append('customerEmail', event.target.elements.customerEmail.value);
        formData.append('customerContact', event.target.elements.customerContact.value);
        formData.append('outlet', event.target.elements.outlet.value);
        formData.append('SKU', event.target.elements.SKU.value);
        formData.append('serialNumber', event.target.elements.serialNumber.value);
        formData.append('customerRemarks', event.target.elements.customerRemarks.value);
        formData.append('POS', event.target.elements.POS.value);
        const datePurchased = new Date(event.target.elements.datePurchased.value);
        datePurchased.setUTCHours(0, 0, 0, 0);
        formData.append('datePurchased', datePurchased.toISOString());
        const dateCollected = new Date(event.target.elements.dateCollected.value);
        dateCollected.setUTCHours(0, 0, 0, 0);
        formData.append('dateCollected', dateCollected.toISOString());

        const response = await fetch(`${apiBaseUrl}/api/protected/registerWarranty`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            body: formData
        });

        if (response.ok) {
            setLoading(false);
            navigate('/dashboard');
        } else {
            console.error("Error submitting.");
        };
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <header className='header'>
                <h1>Register Warranty</h1>
            </header>
            <div className='back'>
                <button type="button" onClick={back} >Back</button>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='form-grid'>
                    <div className='form-column'>
                        <h4 style={{ marginBottom: '0', marginTop: '1rem', fontSize: '18px' }}>Customer Information</h4>
                        <label htmlFor="customerName" style={{ fontWeight: '500' }}>* Full name:</label>
                        <input type="text" placeholder="Enter name" name="customerName" id="customerName" maxLength={320} required onInput={handleInputChange} />
                        <label htmlFor="customerEmail" style={{ fontWeight: '500' }}>* Email:</label>
                        <input type="email" placeholder="example@domain.com" name="customerEmail" id="customerEmail" maxLength={320} required onInput={handleInputChange} />
                        <label htmlFor="customerContact" style={{ fontWeight: '500' }}>* Contact:</label>
                        <input className='phone' type="number" placeholder="Enter contact" name="customerContact" id="customerContact" maxLength={15} onInput={handleInputChange} required />
                    </div>

                    <div className='form-column'>
                        <h4 style={{ marginBottom: '0', marginTop: '1rem', fontSize: '18px' }}>Device Information</h4>
                        <label htmlFor="POS" style={{ fontWeight: '500' }}>* POS:</label>
                        <input type="text" placeholder="Enter POS" name="POS" id="POS" maxLength={100} required />
                        <label htmlFor="SKU" style={{ fontWeight: '500' }}>* SKU:</label>
                        <select defaultValue={""} name="SKU" id="SKU" required>
                            <option value="" disabled hidden>Select SKU</option>
                            {devices && devices.map((device) => (
                                <option key={device.fullname} value={`${device.fullname.split(' : ')[1]} - ${device.description}`}>
                                    {device.fullname.split(' : ')[1]} - {device.description}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="serialNumber" style={{ fontWeight: '500' }}>* Serial Number:</label>
                        <input type="text" placeholder="Enter serial number" name="serialNumber" id="serialNumber" maxLength={100} required />
                    </div>

                    <div className='form-column'>
                        <h4 style={{ marginBottom: '0', marginTop: '1rem', fontSize: '18px' }}>Warranty Information</h4>
                        <label htmlFor="outlet" style={{ fontWeight: '500' }}>* Current outlet:</label>
                        <select defaultValue={""} name="outlet" id="outlet" required>
                            <option value="" disabled hidden>Select outlet</option>
                            {outlets && outlets.map((outlet) => (
                                <option key={outlet.id} value={outlet.name.split('|')[1]}>
                                    {outlet.name.split('|')[1]}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="datePurchased" style={{ fontWeight: '500' }}>* Date Purchased:</label>
                        <input type="date" name="datePurchased" id="datePurchased" className='dateInput' required />
                        <label htmlFor="dateCollected" style={{ fontWeight: '500' }}>* Date Collected:</label>
                        <input type="date" name="dateCollected" id="dateCollected" className='dateInput' required />
                    </div>

                    <div className='form-column' style={{ justifyContent: 'space-between' }}>
                        <div>
                            <h4 style={{ marginBottom: '1rem', marginTop: '1rem', fontSize: '18px' }}>Register</h4>
                            <label htmlFor="customerRemarks" style={{ marginTop: 0 }}>Remarks:</label><br />
                            <textarea
                                placeholder="Enter remarks"
                                name="customerRemarks"
                                rows={5}
                                id="customerRemarks"
                                maxLength={300}
                                style={{
                                    width: '100%',
                                    padding: 0,
                                }}
                            />
                        </div>
                        <button type='submit' className='register'>Register Warranty</button>
                    </div>
                </div>
            </form >
        </div >
    );
};