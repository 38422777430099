import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from './context';

const NotFound = () => {
    const { user, loading } = useContext(UserContext);

    if (loading) {
        return null;
    }

    if (user) {
        return <Navigate to="/dashboard" replace />;
    }

    return <Navigate to="/" replace />;
};

export default NotFound;