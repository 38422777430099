import React, { useContext, useEffect, useState, useCallback } from 'react';
import Modal from 'react-modal';
import { useNavigate, Navigate } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import ReactLoading from 'react-loading';
import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';

import { UserContext } from './context';
import './newJob.css'
import './assets/styles/general.styles.css';
import repairAcknowledgementSlip from './assets/template/repairAcknowledgementSlip.pdf';

Modal.setAppElement('#root');

const { hostname } = window.location;
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

export default function NewJob() {
    const { user, accessToken, accessiblePages } = useContext(UserContext);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [devices, setDevices] = useState(null);
    const [outlets, setOutlets] = useState(null);
    const [faults, setFaults] = useState(null);
    const [firstFault, setFirstFault] = useState('');
    const [secondFault, setSecondFault] = useState('');
    const [parts, setParts] = useState(null);
    const [warranty, setWarranty] = useState('');

    const [images, setImages] = useState([]);
    const [displayImages, setDisplayImages] = useState([]);
    const [imageNames, setImageNames] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [imageError, setImageError] = useState("");

    const back = () => {
        navigate('/dashboard');
    };

    const devicesData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/devices`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ apiBaseUrl })
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();
            setDevices(responseBody);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    const outletsData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/outlets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ apiBaseUrl })
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();
            setOutlets(responseBody);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    const faultsAndPartsData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/faultsAndParts`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();
            setFaults(responseBody.faults);
            setParts(responseBody.parts);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    useEffect(() => {
        if (accessiblePages.includes('newJob')) {
            devicesData();
            outletsData();
            faultsAndPartsData();
        };
    }, [accessiblePages, devicesData, outletsData, faultsAndPartsData]);

    if (!accessiblePages.includes('newJob')) {
        return <Navigate to="/dashboard" />;
    };

    const handleInputChange = (e) => {
        const { name, value, maxLength } = e.target;
        if (value.length > maxLength) {
            e.target.value = value.slice(0, maxLength);
        }
        if (name === 'customerName') {
            e.target.value = value.toUpperCase();
        }
        if (name === 'customerEmail') {
            e.target.value = value.toLowerCase();
        }
    };

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1280,
                1280,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });

    const resizeDisplayFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1280,
                1280,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    const handleImageChange = async (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 5) {
            setImageError("Maximum of 5 images. Any additional will not be uploaded.");
            files.splice(5);
        } else {
            setImageError("");
        }
        const fileNames = files.map(file => file.name);
        setImageNames([...imageNames, ...fileNames]);
        const resizedImagePromises = files.map(file => resizeFile(file));
        const resizedDisplayImagePromises = files.map(file => resizeDisplayFile(file));
        if (e.target.files) {
            try {
                const resizedImages = await Promise.all(resizedImagePromises);
                setImages((prevImages) => [...prevImages, ...resizedImages]);
                const resizedDisplayImages = await Promise.all(resizedDisplayImagePromises);
                setDisplayImages((prevImages) => [...prevImages, ...resizedDisplayImages]);
            } catch (error) {
                console.error("Error submitting image: ", error)
            }
        };
    };

    const handleImageRemoval = (indexToRemove) => {
        setImages(images.filter((_, index) => index !== indexToRemove));
        setImageNames(imageNames.filter((_, index) => index !== indexToRemove));
        setDisplayImages(displayImages.filter((_, index) => index !== indexToRemove));
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        const formData = new FormData();
        const formData2 = new FormData(event.target);
        const data = Object.fromEntries(formData2.entries());
        const dataArray = Object.values(data).filter(value => !(value instanceof File));
        const completeJobData = [...dataArray];

        const existingPdfBytes = await fetch(repairAcknowledgementSlip).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const fontSize = 11;

        let today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy;
        firstPage.drawText(completeJobData[0], {
            x: 75,
            y: 568,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(completeJobData[0], {
            x: 75,
            y: 228,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(completeJobData[2], {
            x: 75,
            y: 552,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(completeJobData[2], {
            x: 75,
            y: 212,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(completeJobData[7], {
            x: 75,
            y: 521,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(completeJobData[7], {
            x: 75,
            y: 181,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(completeJobData[11], {
            x: 75,
            y: 505,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(completeJobData[11], {
            x: 75,
            y: 165,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(completeJobData[3], {
            x: 75,
            y: 489,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(completeJobData[3], {
            x: 75,
            y: 149,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(completeJobData[4], {
            x: 75,
            y: 472,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(completeJobData[4], {
            x: 75,
            y: 132,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(`${user.posid}`, {
            x: 75,
            y: 456,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(`${user.posid}`, {
            x: 75,
            y: 116,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(today, {
            x: 75,
            y: 440,
            size: fontSize,
            color: rgb(0, 0, 0),
        });
        firstPage.drawText(today, {
            x: 75,
            y: 100,
            size: fontSize,
            color: rgb(0, 0, 0),
        });

        formData.append('employeeId', user.posid);
        formData.append('customerName', event.target.elements.customerName.value);
        formData.append('customerEmail', event.target.elements.customerEmail.value);
        formData.append('customerContact', event.target.elements.customerContact.value);
        formData.append('fault1', event.target.elements.fault1.value);
        formData.append('fault2', event.target.elements.fault2.value);
        formData.append('outlet', event.target.elements.outlet.value);
        formData.append('collectionOutlet', event.target.elements.collectionOutlet.value);
        formData.append('SKU', event.target.elements.SKU.value);
        formData.append('serialNumber', event.target.elements.serialNumber.value);
        formData.append('part', event.target.elements.part.value);
        formData.append('vip', event.target.elements.vip.value);
        formData.append('warranty', event.target.elements.warranty.value);
        formData.append('rootJobId', event.target.elements.rootJobId.value);
        formData.append('customerRemarks', event.target.elements.customerRemarks.value);
        formData.append('POS', event.target.elements.POS.value);
        if (warranty === 'false') {
            formData.append('assessmentFeeTaxInvoice', event.target.elements.assessmentFeeTaxInvoice.value);
        };
        if (images.length !== 5) {
            setImageError("Please submit 5 images of the device.")
            setLoading(false);
            return;
        };
        images.forEach((image, index) => {
            formData.append(`images`, image, `image-${index}.jpg`);
        });

        const response = await fetch(`${apiBaseUrl}/api/protected/newjob`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            body: formData
        });

        if (response.ok) {
            const idData = await response.json();
            firstPage.drawText(`${idData.data}`, {
                x: 75,
                y: 537,
                size: fontSize,
                color: rgb(0, 0, 0),
            });
            firstPage.drawText(`${idData.data}`, {
                x: 75,
                y: 197,
                size: fontSize,
                color: rgb(0, 0, 0),
            });
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            saveAs(blob, `Repair Acknowledgement Slip ${completeJobData[0]}.pdf`);

            setLoading(false);
            navigate('/dashboard');
        } else if (response.status === 422) {
            setErrorMessage("Invalid Root Job ID.");
            setLoading(false);
        } else {
            console.error("Error submitting.");
            setErrorMessage("An error occurred while submitting the job.");
            setLoading(false);
        };
    };

    const handleImageNameClick = (image) => {
        setSelectedImage(image);
        setShowImageModal(true);
    };

    const customStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <header className='header'>
                <h1>New Job</h1>
            </header>
            <div className='back'>
                <button type="button" onClick={back} >Back</button>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='form-grid'>
                    <div className='form-column'>
                        <h4 style={{ marginBottom: '0', marginTop: '1rem', fontSize: '18px' }}>Customer Details</h4>
                        <label htmlFor="customerName" style={{ fontWeight: '500' }}>* Full name:</label>
                        <input type="text" placeholder="Enter name" name="customerName" id="customerName" maxLength={320} required onInput={handleInputChange} />
                        <label htmlFor="customerEmail" style={{ fontWeight: '500' }}>* Email:</label>
                        <input type="email" placeholder="example@domain.com" name="customerEmail" id="customerEmail" maxLength={320} required onInput={handleInputChange} />
                        <label htmlFor="customerContact" style={{ fontWeight: '500' }}>* Contact:</label>
                        <input className='phone' type="number" placeholder="Enter contact" name="customerContact" id="customerContact" maxLength={15} onInput={handleInputChange} required />
                        <label htmlFor="outlet" style={{ fontWeight: '500' }}>* Current outlet:</label>
                        <select defaultValue={""} name="outlet" id="outlet" required>
                            <option value="" disabled hidden>Select outlet</option>
                            {outlets && outlets.map((outlet) => (
                                <option key={outlet.id} value={outlet.name.split('|')[1]}>
                                    {outlet.name.split('|')[1]}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="collectionOutlet" style={{ fontWeight: '500' }}>* Collection outlet:</label>
                        <select defaultValue={""} name="collectionOutlet" id="collectionOutlet" required>
                            <option value="" disabled hidden>Select outlet</option>
                            {outlets && outlets.map((outlet) => (
                                <option key={outlet.id} value={outlet.name.split('|')[1]}>
                                    {outlet.name.split('|')[1]}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="vip" style={{ fontWeight: '500' }}>* VIP Status:</label>
                        <select defaultValue={""} name="vip" id="vip" required>
                            <option value="" disabled hidden>Select option</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                    </div>

                    <div className='form-column'>
                        <h4 style={{ marginBottom: '0', marginTop: '1rem', fontSize: '18px' }}>Device Information</h4>
                        <label htmlFor="warranty" style={{ fontWeight: '500' }}>* Warranty covered:</label>
                        <select defaultValue={""} name="warranty" id="warranty" onChange={(e) => setWarranty(e.target.value)} required>
                            <option value="" disabled hidden>Select option</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                        {warranty === 'false' && (
                            <label htmlFor='assessmentFeeTaxInvoice' style={{ fontWeight: '500' }}>* Tax Invoice:</label>
                        )}
                        {warranty === 'false' && (
                            <input type="text" placeholder="Assessment Fee Tax Invoice" name="assessmentFeeTaxInvoice" id="assessmentFeeTaxInvoice" maxLength={100} required />
                        )}
                        <label htmlFor="SKU" style={{ fontWeight: '500' }}>* SKU:</label>
                        <select defaultValue={""} name="SKU" id="SKU" required>
                            <option value="" disabled hidden>Select SKU</option>
                            {devices && devices.map((device) => (
                                <option key={device.fullname} value={device.fullname.split(' : ')[1]}>
                                    {device.fullname.split(' : ')[1]} - {device.description}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="POS" style={{ fontWeight: '500' }}>* POS:</label>
                        <input type="text" placeholder="Enter POS" name="POS" id="POS" maxLength={100} required />
                        <label htmlFor="serialNumber" style={{ fontWeight: '500' }}>* Serial Number:</label>
                        <input type="text" placeholder="Enter serial number" name="serialNumber" id="serialNumber" maxLength={100} required />
                        <label htmlFor="rootJobId">Root Job ID:</label>
                        <input type="number" placeholder="Leave blank for new repairs" name="rootJobId" id="rootJobId" maxLength={100} />
                    </div>

                    <div className='form-column'>
                        <h4 style={{ marginBottom: '0', marginTop: '1rem', fontSize: '18px' }}>Device Issue</h4>
                        <label htmlFor="part" style={{ fontWeight: '500' }}>* Parts received:</label>
                        <select defaultValue={""} name="part" id="part" required>
                            <option value="" disabled hidden>Select part</option>
                            {parts && parts.map((part) => (
                                <option key={part.partid} value={part.part}>
                                    {part.part}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="fault1" style={{ fontWeight: '500' }}>* Fault 1:</label>
                        <select defaultValue={""} name="fault1" id="fault1" onChange={(e) => setFirstFault(e.target.value)} required>
                            <option value="" disabled hidden>Select fault</option>
                            {faults && faults
                                .filter(fault => fault.fault !== secondFault)
                                .map((fault) => (
                                    <option key={fault.faultid} value={fault.fault}>
                                        {fault.fault}
                                    </option>
                                ))
                            }
                        </select>
                        <label htmlFor="fault2">Fault 2:</label>
                        <select defaultValue={""} name="fault2" id="fault2" onChange={(e) => setSecondFault(e.target.value)}>
                            <option value="">Select fault</option>
                            {faults && faults
                                .filter(fault => fault.fault !== firstFault)
                                .map((fault) => (
                                    <option key={fault.faultid} value={fault.fault}>
                                        {fault.fault}
                                    </option>
                                ))
                            }
                        </select>
                        <label htmlFor="customerRemarks">Other remarks:</label>
                        <textarea
                            placeholder="Enter remarks"
                            name="customerRemarks"
                            rows={8}
                            id="customerRemarks"
                            maxLength={300}
                        />
                    </div>

                    <div className='form-column' style={{ justifyContent: 'space-between' }}>
                        <div>
                            <h4 style={{ marginBottom: '1rem', marginTop: '1rem', fontSize: '18px' }}>Device Images</h4>
                            {images.length < 5 && (
                                <>
                                    <label htmlFor="fault1" style={{ fontWeight: '500' }}>* Upload:</label><br />
                                    <input type="file" id="imageUpload" name="imageUpload" accept="image/*" multiple onChange={handleImageChange} style={{ maxWidth: '200px' }} />
                                </>
                            )}
                            {errorMessage !== "" && (<p style={{ color: 'red' }}>{errorMessage}</p>)}
                            {imageError !== "" && (<p style={{ color: 'red' }}>{imageError}</p>)}
                            <div>
                                {imageNames.map((name, index) => (
                                    <div key={index}>
                                        <button
                                            type="button"
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                color: 'black',
                                                textDecoration: 'underline',
                                            }}
                                            onClick={() => handleImageNameClick(displayImages[index])}
                                        >
                                            {name}
                                        </button>
                                        <button type="button" className='clearImage' onClick={() => handleImageRemoval(index)}>X</button>
                                    </div>
                                ))}
                            </div>
                            <Modal
                                isOpen={showImageModal}
                                onRequestClose={() => setShowImageModal(false)}
                                style={customStyle}
                            >
                                <div className="modal-content">
                                    <button onClick={() => setShowImageModal(false)} className="modal-close-btn">x</button>
                                    <img className='newJobImage' src={selectedImage} alt="Selected" />
                                </div>
                            </Modal>
                        </div>
                        <button type='submit' className='submit'>Create Job</button>
                    </div>
                </div>
            </form >
        </div >
    );
};