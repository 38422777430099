import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { UserContext } from './context';
import './assets/styles/details.styles.css'
import './jobStatus.css'

const { hostname } = window.location;
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

const StyledPopover = styled(Popover)(({ theme }) => ({
    pointerEvents: 'none',
    boxShadow: 'none',
    '& .MuiPopover-paper': {
        boxShadow: 'none',
        border: '1px solid black',
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1),
}));

export default function Warranty() {
    const { accessToken } = useContext(UserContext);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [warranty, setWarranty] = useState([]);
    const [warrantyIds, setWarrantyIds] = useState([]);

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const [sortField, setSortField] = useState(queryParams.get('sort') || '');
    const [sortOrder, setSortOrder] = useState(queryParams.get('order') || 'asc');
    const [nameFilter, setNameFilter] = useState(queryParams.get('name') || '');
    const [contactFilter, setContactFilter] = useState(queryParams.get('contact') || '');
    const [dateStartFilter, setDateStartFilter] = useState(queryParams.get('dateStart') || '');
    const [dateEndFilter, setDateEndFilter] = useState(queryParams.get('dateEnd') || '');
    const [skuFilter, setSkuFilter] = useState(queryParams.get('sku') || '');
    const [posFilter, setPosFilter] = useState(queryParams.get('pos') || '');
    const [serialFilter, setSerialFilter] = useState(queryParams.get('serial') || '');

    const [filterChanged, setFilterChanged] = useState(false);
    const [resetFilter, setResetFilter] = useState(false);

    const [currentPage, setCurrentPage] = useState(queryParams.get('page') || 1);
    const pageSize = 20;
    const [totalCount, setTotalCount] = useState(0);
    const totalPages = Math.ceil(totalCount / pageSize);
    const [searchPage, setSearchPage] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState('');

    const back = () => {
        navigate('/dashboard');
    };

    const fetchDataRef = useRef();

    const fetchData = useCallback(async () => {
        setLoading(true);
        const params = new URLSearchParams({
            page: currentPage,
            limit: pageSize,
            sortField: sortField,
            sortOrder: sortOrder,
            nameFilter: nameFilter,
            contactFilter: contactFilter,
            dateStartFilter: dateStartFilter,
            dateEndFilter: dateEndFilter,
            skuFilter: skuFilter,
            posFilter: posFilter,
            serialFilter: serialFilter,
        });

        const response = await fetch(`${apiBaseUrl}/api/protected/warranty?${params}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        const data = await response.json();
        setWarranty(data.data);
        setTotalCount(data.totalCount);
        setWarrantyIds(data.warrantyIds);
        setFilterChanged(false);
        setLoading(false);
    }, [currentPage, pageSize, sortField, sortOrder, nameFilter, contactFilter, dateStartFilter, dateEndFilter, skuFilter, posFilter, serialFilter, accessToken]);

    fetchDataRef.current = fetchData;

    useEffect(() => {
        fetchDataRef.current();
        setResetFilter(false);
    }, [currentPage, pageSize, sortField, sortOrder, resetFilter]);

    const applyFilters = () => {
        fetchDataRef.current();
        setFilterChanged(false);
    };

    const downloadCSV = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/warrantyDownload`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ warrantyIds })
            });

            console.log(JSON.stringify({ warrantyIds }));

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'warranty-data.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error("Failed to download CSV file.");
            }
        } catch (error) {
            console.error("Error downloading CSV file", error);
        }
    };

    const sortData = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        };
    };

    const renderPageButtons = () => {
        const pageButtons = [];

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageButtons.push(
                    <button
                        key={i}
                        onClick={() => handlePageChange(i)}
                        disabled={currentPage === i}
                        style={{
                            backgroundColor: currentPage === i ? '#333' : 'black',
                            border: currentPage === i ? '1.5px solid #333' : 'black'
                        }}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            if (currentPage <= 3) {
                for (let i = 1; i <= 5; i++) {
                    pageButtons.push(
                        <button
                            key={i}
                            onClick={() => handlePageChange(i)}
                            disabled={currentPage === i}
                            style={{
                                backgroundColor: currentPage === i ? '#333' : 'black',
                                border: currentPage === i ? '1.5px solid #333' : 'black'
                            }}
                        >
                            {i}
                        </button>
                    );
                }
                pageButtons.push(<span key="ellipsis1">...</span>);
                pageButtons.push(
                    <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
                        {totalPages}
                    </button>
                );
            } else if (currentPage >= totalPages - 2) {
                pageButtons.push(
                    <button key={1} onClick={() => handlePageChange(1)}>
                        1
                    </button>
                );
                pageButtons.push(<span key="ellipsis2">...</span>);
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    pageButtons.push(
                        <button
                            key={i}
                            onClick={() => handlePageChange(i)}
                            disabled={currentPage === i}
                            style={{
                                backgroundColor: currentPage === i ? '#333' : 'black',
                                border: currentPage === i ? '1.5px solid #333' : 'black'
                            }}
                        >
                            {i}
                        </button>
                    );
                }
            } else {
                pageButtons.push(
                    <button key={1} onClick={() => handlePageChange(1)}>
                        1
                    </button>
                );
                pageButtons.push(<span key="ellipsis3">...</span>);
                for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                    pageButtons.push(
                        <button
                            key={i}
                            onClick={() => handlePageChange(i)}
                            disabled={currentPage === i}
                            style={{
                                backgroundColor: currentPage === i ? '#333' : 'black',
                                border: currentPage === i ? '1.5px solid #333' : 'black'
                            }}
                        >
                            {i}
                        </button>
                    );
                }
                pageButtons.push(<span key="ellipsis4">...</span>);
                pageButtons.push(
                    <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
                        {totalPages}
                    </button>
                );
            }
        }

        return pageButtons;
    };

    const handleSearchPageChange = (e) => {
        setSearchPage(e.target.value);
    };

    const handleGoToPage = () => {
        const page = parseInt(searchPage);
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            setSearchPage(page);
        }
    };

    const handlePopoverOpen = (event, value) => {
        if (/^\d{4,7}$/.test(value)) {
            setAnchorEl(event.currentTarget);
            fetchPopoverData(value);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const fetchPopoverData = async (value) => {
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/employeeName`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ apiBaseUrl, value })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setPopoverContent(data);
        } catch (error) {
            console.error("Failed to fetch popover data:", error);
            setPopoverContent('Error fetching name');
        }
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <header className='header'>
                <h1>Check Warranty</h1>
            </header>
            <div className='back spaceBetween'>
                <button type="button" onClick={back}>Back</button>
                <button type="button" onClick={downloadCSV}>Download Data</button>
            </div>
            <div>
                <div className='filterIsland'>
                    <h3 style={{ marginLeft: '1.5rem', paddingTop: '1rem', marginBottom: '0' }}>Filter</h3>
                    <div className='filters'>
                        <div className='filter-column filter'>

                            <div className='filter-group-1'>
                                <label>Name:</label>
                                <input type="text" value={nameFilter} onChange={(e) => { setNameFilter(e.target.value); setFilterChanged(true); }} maxLength={100} />
                                <button style={{ marginLeft: '1rem' }} onClick={() => { setNameFilter(''); setFilterChanged(true); }}>X</button>
                            </div>

                            <div className='filter-group-1'>
                                <label>Contact:</label>
                                <input className='phone' type="number" value={contactFilter} onChange={(e) => { setContactFilter(e.target.value); setFilterChanged(true); }} />
                                <button style={{ marginLeft: '1rem' }} onClick={() => { setContactFilter(''); setFilterChanged(true); }}>X</button>
                            </div>

                            <div className='filter-group-1'>
                                <label>SKU:</label>
                                <input type="text" value={skuFilter} onChange={(e) => { setSkuFilter(e.target.value); setFilterChanged(true); }} maxLength={100} />
                                <button style={{ marginLeft: '1rem' }} onClick={() => { setSkuFilter(''); setFilterChanged(true); }}>X</button>
                            </div>

                        </div>
                        <div className='filter-column filter'>

                            <div className='filter-group-2'>
                                <label>POS:</label>
                                <input type="text" value={posFilter} onChange={(e) => { setPosFilter(e.target.value); setFilterChanged(true); }} maxLength={100} />
                                <button style={{ marginLeft: '1rem' }} onClick={() => { setPosFilter(''); setFilterChanged(true); }}>X</button>
                            </div>

                            <div className='filter-group-2'>
                                <label>Serial Number:</label>
                                <input type="text" value={serialFilter} onChange={(e) => { setSerialFilter(e.target.value); setFilterChanged(true); }} maxLength={100} />
                                <button style={{ marginLeft: '1rem' }} onClick={() => { setSerialFilter(''); setFilterChanged(true); }}>X</button>
                            </div>

                            <div className='filter-group-2 dateFlexAlign'>
                                <label>Date Submitted:</label>
                                <div className='date'>
                                    <input type="date" className='dateInput' value={dateStartFilter} onChange={(e) => { setDateStartFilter(e.target.value); setFilterChanged(true); }} />
                                    <p style={{ paddingLeft: '3px', paddingRight: '3px' }}>-</p>
                                    <input type="date" className='dateInput' value={dateEndFilter} onChange={(e) => { setDateEndFilter(e.target.value); setFilterChanged(true); }} />
                                    <button style={{ marginLeft: '1rem' }} onClick={() => { setDateStartFilter(''); setDateEndFilter(''); setFilterChanged(true); }}>X</button>
                                </div>
                            </div>

                        </div>

                        <div className='clear-all-container'>
                            {filterChanged && (
                                <button className='mediaButton' style={{ marginRight: '0.5rem' }} onClick={applyFilters}>Apply Filters</button>
                            )}
                            {!filterChanged && (
                                <button className='mediaButton' style={{ marginRight: '0.5rem', backgroundColor: 'gray', border: 'gray', cursor: 'default' }} disabled>Apply Filters</button>
                            )}
                            <button className='mediaButton' onClick={() => {
                                setNameFilter('');
                                setContactFilter('');
                                setDateStartFilter('');
                                setDateEndFilter('');
                                setSkuFilter('');
                                setPosFilter('');
                                setSerialFilter('');
                                setFilterChanged(true);
                                setResetFilter(true);
                            }}>
                                Clear Filters
                            </button>
                        </div>
                    </div>
                </div>
                <div className='table-wrapper mobileScroll'>
                    <table className='fitTable'>
                        <thead className='head'>
                            <tr>
                                <th className='sort' onClick={() => sortData('warrantyid')}>Warranty Id {sortField === 'warrantyid' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('sku')}>SKU {sortField === 'sku' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('pos')}>POS {sortField === 'pos' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('serialnumber')}>Serial Number {sortField === 'serialnumber' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('submittimestamp')}>Submitted At {sortField === 'submittimestamp' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('outletpurchased')}>Outlet Purchased {sortField === 'outletpurchased' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('customername')}>Customer Name {sortField === 'customername' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th>Customer Email</th>
                                <th className='sort' onClick={() => sortData('customercontact')}>Customer Contact {sortField === 'customercontact' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('purchaseddate')}>Purchased Date {sortField === 'purchaseddate' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('collectiondate')}>Collection Date {sortField === 'collectiondate' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('counter')}>Counter {sortField === 'counter' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                                <th className='sort' onClick={() => sortData('employeeid')}>Submitted By {sortField === 'employeeid' && (sortOrder === 'asc' ? '↑' : '↓')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {warranty.map((warranty) => (
                                <tr key={warranty.warrantyid}>
                                    <td>
                                        <Link to={`/warranty/${warranty.warrantyid}?name=${nameFilter}&contact=${contactFilter}&dateStart=${dateStartFilter}&dateEnd=${dateEndFilter}&sku=${skuFilter}&pos=${posFilter}&serial=${serialFilter}&sort=${sortField}&order=${sortOrder}&page=${currentPage}`}>
                                            {warranty.warrantyid}
                                        </Link>
                                    </td>
                                    <td>{warranty.sku}</td>
                                    <td>{warranty.pos}</td>
                                    <td>{warranty.serialnumber}</td>
                                    <td>{new Date(warranty.submittimestamp).toLocaleString('en-gb')}</td>
                                    <td>{warranty.outletpurchased}</td>
                                    <td>{warranty.customername}</td>
                                    <td>{warranty.customeremail}</td>
                                    <td>{warranty.customercontact}</td>
                                    <td>{new Date(warranty.purchasedate).toLocaleDateString('en-gb')}</td>
                                    <td>{new Date(warranty.collectiondate).toLocaleDateString('en-gb')}</td>
                                    <td>{warranty.counter}</td>

                                    <td>
                                        <p
                                            aria-owns={open ? 'mouse-over-popover' : undefined}
                                            aria-haspopup="true"
                                            onMouseEnter={(event) => handlePopoverOpen(event, warranty.employeeid)}
                                            onMouseLeave={handlePopoverClose}
                                            style={{ margin: 0 }}
                                        >
                                            {warranty.employeeid}
                                        </p>
                                        <StyledPopover
                                            id="mouse-over-popover"
                                            open={open}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                        >
                                            <StyledTypography>{popoverContent}</StyledTypography>
                                        </StyledPopover>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className='pagination'>
                    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            style={{ marginRight: '1rem' }}
                        >
                            &lt;
                        </button>
                        {renderPageButtons()}
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            style={{ marginLeft: '1rem' }}
                        >
                            &gt;
                        </button>
                    </div>
                    <div style={{ marginBottom: '1rem' }}>
                        <label style={{ marginRight: '1rem' }}>Go to page:</label>
                        <input
                            type="number"
                            min={1}
                            max={totalPages}
                            value={searchPage}
                            onChange={handleSearchPageChange}
                        />
                        <button style={{ marginLeft: '1rem' }} onClick={handleGoToPage}>Go</button>
                    </div>
                    {/* <div>
                        <input
                            type="number"
                            min={1}
                            max={100}
                            value={pageSize}
                            onChange={(e) => setPageSize(e.target.value)}
                        />
                    </div> */}
                </div>
            </div>
        </div >
    );
};