import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { UserContext } from './context';

import './assets/styles/styles.css'
import './login.css';
import background from './assets/images/161120 Hysses Brand ID.jpg';
import logo from './assets/images/Hysses logo in white.png';

export default function Login() {
    const { setUser, setAccessToken, setAccessiblePages } = useContext(UserContext);
    const navigate = useNavigate();
    const { hostname } = window.location;
    const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

    const [loading, setLoading] = useState(false);
    const [errorMessageLogin, setErrorMessageLogin] = useState('');

    const handleLogin = async (event) => {
        setLoading(true);
        event.preventDefault();
        const posid = event.target.elements.posid.value;
        const email = event.target.elements.email.value;

        const response = await fetch(`${apiBaseUrl}/api/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ posid, email, apiBaseUrl }),
            credentials: 'include'
        });

        if (response.ok) {
            const { user, accessToken, accessiblePages } = await response.json();
            setAccessToken(accessToken);
            setUser(user);
            setAccessiblePages(accessiblePages);
            navigate('/dashboard');
        } else if (response.status === 401) {
            const errorData = await response.json();
            setErrorMessageLogin(errorData.error);
        } else {
            console.error("Error logging in.");
        };
        setLoading(false);
    };

    return (
        <div className='login-container'>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <img src={background} alt='background' className='background-image' />
            <img src={logo} alt='hysses-logo' className='logo-image' />
            <div className='form-container'>
                <form className='form-elements' onSubmit={handleLogin}>
                    <h5>Login:</h5>
                    <input type="text" placeholder="POS ID" name="posid" required maxLength={100} />
                    <input type='email' placeholder='Email' name='email' required maxLength={320} />
                    {errorMessageLogin !== '' && (
                        <p style={{ color: 'red' }}>{errorMessageLogin}</p>
                    )}
                    <button type='submit'>Login</button>
                </form>
            </div>
        </div>
    );
};