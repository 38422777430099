import React, { useState, useEffect, useCallback, useContext } from 'react';
import Modal from 'react-modal';
import { useParams, useNavigate, useLocation, Navigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { UserContext } from './context';
import './assets/styles/repair.styles.css'

const { hostname } = window.location;
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

Modal.setAppElement('#root');

const WarrantyDetails = () => {
    const { user, accessToken, accessiblePages } = useContext(UserContext);
    const navigate = useNavigate();
    let { warrantyId } = useParams();

    const [warranty, setWarranty] = useState([]);
    const [loading, setLoading] = useState(true);
    const [devices, setDevices] = useState(null);
    const [outlets, setOutlets] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        collectiondate: '',
        counter: 0,
        customeremail: '',
        customercontact: '',
        customerid: '',
        customername: '',
        employeeid: 0,
        outletpurchased: '',
        pos: '',
        purchasedate: '',
        remarks: '',
        serialnumber: '',
        sku: '',
        submittimestamp: '',
        warrantyid: 0,
    });

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const sortField = queryParams.get('sort') || '';
    const sortOrder = queryParams.get('order') || 'asc';
    const contactFilter = queryParams.get('contact') || '';
    const dateStartFilter = queryParams.get('dateStart') || '';
    const dateEndFilter = queryParams.get('dateEnd') || '';
    const skuFilter = queryParams.get('sku') || '';
    const posFilter = queryParams.get('pos') || '';
    const serialFilter = queryParams.get('serial') || '';
    const page = queryParams.get('page') || 1;

    const [filters,] = useState({
        contact: contactFilter,
        dateStart: dateStartFilter,
        dateEnd: dateEndFilter,
        sku: skuFilter,
        pos: posFilter,
        serial: serialFilter,
        sort: sortField,
        order: sortOrder,
        page: page
    });

    const [showExchangeModal, setShowExchangeModal] = useState(false);
    const [exchangeSKU, setExchangeSKU] = useState('')
    const [exchangeSerial, setExchangeSerial] = useState('')
    const [exchangeReason, setExchangeReason] = useState('')

    const [shouldFetch, setShouldFetch] = useState(false);

    const back = () => {
        const { contact, dateStart, dateEnd, sku, pos, serial, sort, order, page } = filters;
        navigate(`/warranty?contact=${contact}&dateStart=${dateStart}&dateEnd=${dateEnd}&sku=${sku}&pos=${pos}&serial=${serial}&sort=${sort}&order=${order}&page=${page}`);
    };

    const devicesData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/devices`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ apiBaseUrl })
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();
            setDevices(responseBody);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    const outletsData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/outlets`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ apiBaseUrl })
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();
            setOutlets(responseBody);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/warranty/${warrantyId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setWarranty(data);
            setFormData({
                collectiondate: formatDate(data.collectiondate),
                counter: data.counter,
                customeremail: data.customeremail,
                customercontact: data.customercontact,
                customerid: data.customerid,
                customername: data.customername,
                employeeid: data.employeeid,
                outletpurchased: data.outletpurchased,
                pos: data.pos,
                purchasedate: formatDate(data.purchasedate),
                remarks: data.remarks,
                serialnumber: data.serialnumber,
                sku: data.sku,
                submittimestamp: data.submittimestamp,
                warrantyid: data.warrantyid
            });
        } catch (error) {
            console.error("Failed to fetch warranty details:", error);
        } finally {
            setLoading(false);
        }
    }, [warrantyId, accessToken]);

    useEffect(() => {
        if (shouldFetch) {
            fetchData();
            setShouldFetch(false);
        };
    }, [shouldFetch, fetchData]);

    useEffect(() => {
        if (accessiblePages.includes('warranty')) {
            devicesData();
            outletsData();
            fetchData();
        };
    }, [accessiblePages, devicesData, outletsData, fetchData]);

    if (!accessiblePages.includes('warranty')) {
        return <Navigate to="/dashboard" />;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!editMode) return;
        if (JSON.stringify(formData) === JSON.stringify(warranty)) {
            return;
        }
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/updateWarranty/${warrantyId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                fetchData();
                setEditMode(false);
            } else {
                console.error('Failed to update warranty');
            }
        } catch (error) {
            console.error('Error updating warranty:', error);
        }
    };

    const handleSubmitExchange = async () => {
        const employeeid = user.posid

        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/exchange/${warrantyId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ exchangeSKU, exchangeSerial, exchangeReason, employeeid })
            });
            if (response.ok) {
                fetchData();
            } else {
                console.error('Failed to exchange device');
            }
        } catch (error) {
            console.error('Error exchanging device:', error);
        }
    };

    const customStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <header className='header'>
                <h2>Warranty ID: {warrantyId}</h2>
            </header>
            <div className='back spaceBetween'>
                <button type="button" onClick={back}>Back</button>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='form-grid'>
                    <div className='form-column'>
                        <h4 style={{ marginBottom: '0', marginTop: '1rem', fontSize: '18px' }}>Customer Information</h4>
                        <label htmlFor="customername" style={{ fontWeight: '500' }}>* Full name:</label>
                        <input value={formData.customername} disabled={!editMode} type="text" placeholder="Enter name" name="customername" id="customername" maxLength={320} required onChange={handleInputChange} />
                        <label htmlFor="customeremail" style={{ fontWeight: '500' }}>* Email:</label>
                        <input value={formData.customeremail} disabled={!editMode} type="email" placeholder="example@domain.com" name="customeremail" id="customeremail" maxLength={320} required onChange={handleInputChange} />
                        <label htmlFor="customercontact" style={{ fontWeight: '500' }}>* Contact:</label>
                        <input value={formData.customercontact} disabled={!editMode} className='phone' type="number" placeholder="Enter contact" name="customercontact" id="customercontact" maxLength={15} onChange={handleInputChange} required />
                    </div>

                    <div className='form-column'>
                        <h4 style={{ marginBottom: '0', marginTop: '1rem', fontSize: '18px' }}>Device Information</h4>
                        <label htmlFor="pos" style={{ fontWeight: '500' }}>* POS:</label>
                        <input value={formData.pos} disabled={!editMode} type="text" placeholder="Enter POS" name="pos" id="pos" maxLength={100} onChange={handleInputChange} required />
                        <label htmlFor="sku" style={{ fontWeight: '500' }}>* SKU:</label>
                        <select value={formData.sku} disabled={!editMode} name="sku" id="sku" onChange={handleInputChange} required>
                            <option value="" disabled hidden>Select SKU</option>
                            {devices && devices.map((device) => (
                                <option key={device.fullname} value={`${device.fullname.split(' : ')[1]} - ${device.description}`}>
                                    {device.fullname.split(' : ')[1]} - {device.description}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="serialnumber" style={{ fontWeight: '500' }}>* Serial Number:</label>
                        <input value={formData.serialnumber} disabled={!editMode} type="text" placeholder="Enter serial number" name="serialnumber" id="serialnumber" maxLength={100} onChange={handleInputChange} required />
                    </div>

                    <div className='form-column'>
                        <h4 style={{ marginBottom: '0', marginTop: '1rem', fontSize: '18px' }}>Warranty Information</h4>
                        <label htmlFor="outletpurchased" style={{ fontWeight: '500' }}>* Current outlet:</label>
                        <select value={formData.outletpurchased} disabled={!editMode} name="outletpurchased" id="outletpurchased" onChange={handleInputChange} required>
                            <option value="" disabled hidden>Select outlet</option>
                            {outlets && outlets.map((outlet) => (
                                <option key={outlet.id} value={outlet.name.split('|')[1]}>
                                    {outlet.name.split('|')[1]}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="purchasedate" style={{ fontWeight: '500' }}>* Date Purchased:</label>
                        <input value={formData.purchasedate} disabled={!editMode} type="date" name="purchasedate" id="purchasedate" className='dateInput' onChange={handleInputChange} required />
                        <label htmlFor="collectiondate" style={{ fontWeight: '500' }}>* Date Collected:</label>
                        <input value={formData.collectiondate} disabled={!editMode} type="date" name="collectiondate" id="collectiondate" className='dateInput' onChange={handleInputChange} required />
                    </div>

                    <div className='form-column' style={{ justifyContent: 'space-between' }}>
                        <div>
                            <h4 style={{ marginBottom: '1rem', marginTop: '1rem', fontSize: '18px' }}>Edit</h4>
                            <label htmlFor="remarks" style={{ marginTop: 0 }}>Remarks:</label><br />
                            <textarea
                                value={formData.remarks}
                                disabled={!editMode}
                                placeholder="Enter remarks"
                                name="remarks"
                                rows={5}
                                id="remarks"
                                maxLength={300}
                                style={{
                                    width: '100%',
                                    padding: 0,
                                }}
                                onChange={handleInputChange}
                            />
                        </div>
                        {editMode ? (
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem' }}>
                                <button className="editWarranty" type="submit">Save</button>
                                <button
                                    className="editWarranty"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setEditMode(false);
                                        setFormData({
                                            collectiondate: formatDate(warranty.collectiondate),
                                            counter: warranty.counter,
                                            customeremail: warranty.customeremail,
                                            customercontact: warranty.customercontact,
                                            customerid: warranty.customerid,
                                            customername: warranty.customername,
                                            employeeid: warranty.employeeid,
                                            outletpurchased: warranty.outletpurchased,
                                            pos: warranty.pos,
                                            purchasedate: formatDate(warranty.purchasedate),
                                            remarks: warranty.remarks,
                                            serialnumber: warranty.serialnumber,
                                            sku: warranty.sku,
                                            submittimestamp: warranty.submittimestamp,
                                            warrantyid: warranty.warrantyid
                                        });
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem' }}>
                                <button
                                    className="submit"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setEditMode(true);
                                    }}
                                >
                                    Edit Details
                                </button>
                                <button
                                    className="submit"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowExchangeModal(true)
                                    }}
                                >
                                    Exchange
                                </button>
                                <Modal
                                    isOpen={showExchangeModal}
                                    onRequestClose={() => setShowExchangeModal(false)}
                                    style={customStyle}
                                >
                                    <div className="modal-content">
                                        <button onClick={() => setShowExchangeModal(false)} className="modal-close-btn">x</button>
                                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                            <h3 style={{ margin: '1rem' }}>Exchange Device</h3>
                                        </div>
                                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                            <label htmlFor="sku" style={{ fontWeight: '500' }}>* Exchange SKU:</label>
                                            <select value={exchangeSKU} name="sku" id="sku" onChange={e => setExchangeSKU(e.target.value)} style={{ width: 350 }} required>
                                                <option value="" disabled hidden>Select SKU</option>
                                                {devices && devices.map((device) => (
                                                    <option key={device.fullname} value={`${device.fullname.split(' : ')[1]} - ${device.description}`}>
                                                        {device.fullname.split(' : ')[1]} - {device.description}
                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor="serialnumber" style={{ fontWeight: '500', marginTop: '1rem' }}>* Exchange Serial Number:</label>
                                            <input value={exchangeSerial} type="text" placeholder="Enter serial number" name="serialnumber" id="serialnumber" maxLength={100} onChange={e => setExchangeSerial(e.target.value)} required />
                                        </div>
                                        <div style={{ marginTop: '1rem' }}>
                                            <label htmlFor="remarks">Reason for Exchange:</label>
                                            <textarea
                                                value={exchangeReason}
                                                placeholder="Enter reason"
                                                name="remarks"
                                                rows={5}
                                                id="remarks"
                                                maxLength={300}
                                                style={{
                                                    width: '100%',
                                                    padding: 0,
                                                }}
                                                onChange={e => setExchangeReason(e.target.value)}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                                            <button onClick={() => { setShowExchangeModal(false); handleSubmitExchange(); }}>Confirm</button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        )}
                    </div>
                </div>
            </form >
        </div >
    );
};

export default WarrantyDetails;