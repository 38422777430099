import React, { useContext, useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { useParams, useNavigate, Navigate, useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { UserContext } from './context';
import './assets/styles/repair.styles.css'

const { hostname } = window.location;
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

Modal.setAppElement('#root');

const AccountingDetails = () => {
    const { user, accessToken, accessiblePages } = useContext(UserContext);
    const navigate = useNavigate();

    let { jobId } = useParams();
    const [jobs, setJobs] = useState([]);
    const [warranty, setWarranty] = useState('');
    const [loading, setLoading] = useState(true);

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const sortField = queryParams.get('sort') || '';
    const sortOrder = queryParams.get('order') || 'asc';
    const page = queryParams.get('page') || 1;

    const [filters,] = useState({
        sort: sortField,
        order: sortOrder,
        page: page
    });

    const [stage, setStage] = useState('');
    const [imageCheck, setImageCheck] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);

    const [findings, setFindings] = useState('');
    const [addedParts, setAddedParts] = useState([]);
    const [shouldFetch, setShouldFetch] = useState(false);
    const [parts, setParts] = useState(null);

    const [editMode, setEditMode] = useState(false);
    const [salesOrder, setSalesOrder] = useState('');
    const [taxInvoice, setTaxInvoice] = useState('');
    const [taxInvoiceStart, setTaxInvoiceStart] = useState('');
    const [showAccountingModal, setShowAccountingModal] = useState(false);

    const back = () => {
        const { sort, order, page } = filters;
        navigate(`/accounting?sort=${sort}&order=${order}&page=${page}`);
    };

    const inventoryData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/parts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ apiBaseUrl })
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();
            const modifiedParts = responseBody.results.map((part, index) => ({
                ...part.values,
                partid: part.values['GROUP(itemid)'].split(':')[1].trim(),
                index: index + 1,
                locationquantityonhand: Number(part.values['SUM(locationquantityonhand)']),
                unitprice: Number(part.values['SUM(baseprice)'] * 1.09).toFixed(2)
            }));
            setParts(modifiedParts);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/approval/${jobId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const imageUrls = data.images.map(imageData => {
                const uint8Array = new Uint8Array(imageData.imagedata.data);
                const blob = new Blob([uint8Array], { type: 'image/jpeg' });
                const imageUrl = URL.createObjectURL(blob);
                return imageUrl;
            });

            setJobs({ ...data, imageUrls });
        } catch (error) {
            console.error("Failed to fetch job details:", error);
        } finally {
            setLoading(false);
        }
    }, [jobId, accessToken]);

    useEffect(() => {
        if (accessiblePages.includes('accounting')) {
            inventoryData();
            fetchData();
        };
    }, [accessiblePages, jobId, fetchData, inventoryData]);

    useEffect(() => {
        if (jobs.warranty === 1) {
            setWarranty('True');
        } else {
            setWarranty('False');
        };
        if (jobs.charges?.length > 0) {
            const chargesData = jobs.charges[0];
            setSalesOrder(chargesData.salesorder || '');
            setTaxInvoice(chargesData.taxinvoice || '');
            setTaxInvoiceStart(chargesData.taxinvoice || '');
        }
        setStage(jobs.stageofrepair);
        if (jobs.assessment) {
            setFindings(jobs.assessment.findings || '');
        };
        if (jobs.repairParts && parts) {
            const mappedParts = jobs.repairParts.map(repairPart => {
                const partDetails = parts.find(part => part.partid === repairPart.partid);
                return {
                    ...partDetails,
                    quantity: repairPart.quantity,
                    quantitysalvaged: repairPart.quantitysalvaged,
                    quantitychargeable: repairPart.quantitychargeable,
                    totalPrice: partDetails.unitprice * repairPart.quantitychargeable,
                    remarks: repairPart.remarks,
                    instock: repairPart.instock
                };
            });
            setAddedParts(mappedParts);
        };
        if (jobs.imageUrls) {
            setImageCheck(jobs.imageUrls);
        }
    }, [jobs, parts]);

    useEffect(() => {
        if (shouldFetch && accessiblePages.includes('accounting')) {
            fetchData();
            setShouldFetch(false);
        };
    }, [shouldFetch, accessiblePages, fetchData]);

    if (!accessiblePages.includes('accounting')) {
        return <Navigate to="/dashboard" />;
    };

    const handleImageNameClick = (image) => {
        setSelectedImage(image);
        setShowImageModal(true);
    };

    const handleSaveCharges = async () => {
        const employeeId = user.posid;
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/saveCharges/${jobId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ employeeId, stage, taxInvoice, taxInvoiceStart, type: 'tax' })
            });
            if (response.ok) {
                setShouldFetch(true);
            } else {
                console.error("Failed to save charges.");
            };
        } catch (error) {
            console.error("Error saving charges:", error);
        };
    };

    const handleAccounting = async () => {
        setLoading(true);
        const employeeId = user.posid;
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/handleAccounting/${jobId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({ employeeId })
            });
            if (response.ok) {
                setShouldFetch(true);
            } else {
                console.error("Failed to save accounting status.");
            };
        } catch (error) {
            console.error("Error saving accounting status:", error);
        } finally {
            setLoading(false);
        }
    };

    const customStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <header className='header'>
                <h2>Job ID: {jobId}</h2>
            </header>
            <div className='back'>
                <button type="button" onClick={back}>Back</button>
            </div>
            <div className='jobTable'>
                <div className='table-wrapper mobileScroll'>
                    <table className='fitTable'>
                        <thead>
                            <tr className='head'>
                                <th>SKU</th>
                                <th>Part Received</th>
                                <th>POS</th>
                                <th>Serial Number</th>
                                <th>Under Warranty</th>
                                <th>Created At</th>
                                <th>Outlet Received</th>
                                <th>Collection Outlet</th>
                                <th>Customer Name</th>
                                <th>Customer Contact</th>
                                <th>Customer Email</th>
                                <th>VIP</th>
                                <th>Job Status</th>
                                <th>Fault(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{jobs.sku}</td>
                                <td>{jobs.partreceived}</td>
                                <td>{jobs.pos}</td>
                                <td>{jobs.serialnumber}</td>
                                <td>{warranty}</td>
                                <td>{new Date(jobs.timestamp).toLocaleString('en-gb')}</td>
                                <td>{jobs.outletreceived}</td>
                                <td>{jobs.outletcollection}</td>
                                <td>{jobs.customername}</td>
                                <td>{jobs.customercontact}</td>
                                <td>{jobs.customeremail}</td>
                                <td>{jobs.vip === 1 ? 'True' : 'False'}</td>
                                <td>{jobs.stageofrepair}</td>
                                <td>{jobs.fault1}, {jobs.fault2}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='margin'>
                <div>
                    <h4>Customer Remarks:</h4>
                    <p>{jobs.remarks}</p>
                </div>


                {!loading && (
                    <div>
                        <h4>Outlet Images:</h4>
                        {imageCheck.length !== 0 && (
                            <div className='image-buttons'>
                                {jobs.imageUrls.map((imageUrl, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'black',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={() => handleImageNameClick(jobs.imageUrls[index])}
                                    >
                                        {`imageIndex-${index + 1}`}
                                    </button>
                                ))}
                            </div>
                        )}
                        <Modal
                            isOpen={showImageModal}
                            onRequestClose={() => setShowImageModal(false)}
                            style={customStyle}
                        >
                            <div className="modal-content">
                                <button onClick={() => setShowImageModal(false)} className="modal-close-btn">x</button>
                                <img className='newJobImage' src={selectedImage} alt="Selected" />
                            </div>
                        </Modal>
                    </div>
                )}
                {!loading && (
                    <div>
                        <h4>Assessment and Repair Details:</h4>
                        <div>
                            <div className='findingsDiv'>
                                <input className='findingsInput' type="text" value={findings} disabled />
                            </div>
                        </div>
                        <div style={{ marginLeft: 0, marginRight: 0, marginBottom: '1rem' }} className='table-wrapper mobileScroll'>
                            <table className='fitTable'>
                                <thead>
                                    <tr className='head'>
                                        <th>SKU</th>
                                        <th>Description</th>
                                        <th>Unit Price</th>
                                        <th>Quantity Required</th>
                                        <th>Quantity Salvaged</th>
                                        <th>Quantity Chargeable</th>
                                        <th>Stock (Hysses)</th>
                                        <th>Available for Repair?</th>
                                        <th>Total Price</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addedParts.map((part, index) => (
                                        <tr key={index}>
                                            <td>{part.partid}</td>
                                            <td>{part['GROUP(salesdescription)']}</td>
                                            <td>{part?.unitprice ? part.unitprice : ''}</td>
                                            <td>{part.quantity}</td>
                                            <td>{part.quantitysalvaged}</td>
                                            {jobs.warranty === 0 && (
                                                <td>{part.quantitychargeable === null ? '0' : part.quantitychargeable}</td>
                                            )}
                                            {jobs.warranty === 1 && (<td>NA</td>)}
                                            <td>{part.locationquantityonhand}</td>
                                            <td>{part.instock === 1 ? 'True' : 'False'}</td>
                                            <td>{parseFloat(part.totalPrice).toFixed(2)}</td>
                                            <td>{part.remarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="8">Total Net Price</td>
                                        <td>{addedParts.reduce((acc, part) => acc + parseFloat(part.totalPrice), 0).toFixed(2)}</td>
                                    </tr>
                                    {jobs.vip === 1 && (
                                        <tr>
                                            <td colSpan="8">Total VIP Price</td>
                                            <td>{((addedParts.reduce((acc, part) => acc + parseFloat(part.totalPrice), 0)) * 0.9).toFixed(2)}</td>
                                        </tr>
                                    )}
                                </tfoot>
                            </table>
                        </div>
                    </div>
                )}
                {jobs.warranty === 0 && !loading && (
                    <div style={{ marginLeft: 0, marginRight: 0 }} className='table-wrapper mobileScroll'>
                        <table style={{ marginBottom: '0.5rem' }} className='fitTable'>
                            <thead>
                                <tr className='head'>
                                    <th>Items</th>
                                    <th>Reference No.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Hysses Sales Order</td>
                                    <td>{salesOrder}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
                {jobs.warranty === 0 && !loading && (
                    <div style={{ marginLeft: 0, marginRight: 0 }} className='table-wrapper mobileScroll'>
                        <table style={{ marginBottom: '0.5rem' }} className='fitTable'>
                            <thead>
                                <tr className='head'>
                                    <th>Items</th>
                                    <th>Reference No.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Hysses Tax Invoice</td>
                                    {!editMode && (
                                        <td>{taxInvoice}</td>
                                    )}
                                    {editMode && (
                                        <td><input type='text' value={taxInvoice} onChange={e => setTaxInvoice(e.target.value)} maxLength={100} /></td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                        {!editMode && (
                            <button className='mobileButton' onClick={() => { setEditMode(true); }}>Edit</button>
                        )}
                        {editMode && (
                            <button className='mobileButton' onClick={() => { setEditMode(false); handleSaveCharges(); }}>Save</button>
                        )}
                        {(stage === 'Waiting for Accounting' || stage === 'Waiting for Accounting and Spare Parts') && !editMode && taxInvoice !== '' && (
                            <div>
                                <button className='mobileButton' style={{ marginTop: '0.5rem' }} onClick={() => setShowAccountingModal(true)}>Accounting and Payment Complete</button>
                                <div>
                                    <Modal
                                        isOpen={showAccountingModal}
                                        onRequestClose={() => setShowAccountingModal(false)}
                                        style={customStyle}
                                    >
                                        <div className="modal-content">
                                            <button onClick={() => setShowAccountingModal(false)} className="modal-close-btn">x</button>
                                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                                <h3>Confirm accounting and payment complete?</h3>
                                            </div>
                                            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                                <p>This action cannot be undone.</p>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <button onClick={() => { handleAccounting(); setShowAccountingModal(false); }}>Confirm</button>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div >
    );
};

export default AccountingDetails;