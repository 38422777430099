import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { UserContext } from './context';
import './assets/styles/styles.css';

const { hostname } = window.location;
const apiBaseUrl = process.env.NODE_ENV === 'production' ? `https://repair.hysses.com` : `http://${hostname}:3001`;

export default function Settings() {
    const { accessiblePages, accessToken } = useContext(UserContext);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [shouldFetch, setShouldFetch] = useState(false);

    const [pageAccesses, setPageAccesses] = useState([]);
    const [editAccess, setEditAccess] = useState(false);
    const [editedPageAccess, setEditedPageAccess] = useState([]);
    const [newAccessRows, setNewAccessRows] = useState([]);
    const [deletedAccessRows, setDeletedAccessRows] = useState([]);

    const [deviceCategories, setDeviceCategories] = useState([]);
    const [editCategories, setEditCategories] = useState(false);
    const [editedCategories, setEditedCategories] = useState([]);
    const [newCategoryRows, setNewCategoryRows] = useState([]);
    const [deletedCategoryRows, setDeletedCategoryRows] = useState([]);

    const [faults, setFaults] = useState([]);
    const [editFaults, setEditFaults] = useState(false);
    const [editedFaults, setEditedFaults] = useState([]);
    const [newFaultRows, setNewFaultRows] = useState([]);
    const [deletedFaultRows, setDeletedFaultRows] = useState([]);

    const [deviceParts, setDeviceParts] = useState([]);
    const [editParts, setEditParts] = useState(false);
    const [editedParts, setEditedParts] = useState([]);
    const [newPartRows, setNewPartRows] = useState([]);
    const [deletedPartRows, setDeletedPartRows] = useState([]);

    const [emails, setEmails] = useState([]);
    const [editEmails, setEditEmails] = useState(false);
    const [editedEmails, setEditedEmails] = useState([]);
    const [newEmailRows, setNewEmailRows] = useState([]);
    const [deletedEmailRows, setDeletedEmailRows] = useState([]);

    const back = () => {
        navigate('/dashboard');
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/settings`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (!response.ok) {
                throw new Error('Response was not ok');
            }
            const responseBody = await response.json();
            setPageAccesses(responseBody.pageAccess);
            setDeviceCategories(responseBody.deviceCategories);
            setFaults(responseBody.faults);
            setDeviceParts(responseBody.parts);
            setEmails(responseBody.emails);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken]);

    useEffect(() => {
        if (accessiblePages.includes('settings')) {
            fetchData();
        };
    }, [fetchData, accessiblePages]);

    useEffect(() => {
        if (shouldFetch && accessiblePages.includes('settings')) {
            fetchData();
            setShouldFetch(false);
        };
    }, [shouldFetch, accessiblePages, fetchData]);

    if (!accessiblePages.includes('settings')) {
        return <Navigate to="/dashboard" />;
    };

    const handleEditAccessStart = () => {
        setEditAccess(true);
        setEditedPageAccess([...pageAccesses]);
    };

    const handleEditCategoriesStart = () => {
        setEditCategories(true);
        setEditedCategories([...deviceCategories]);
    };

    const handleEditFaultsStart = () => {
        setEditFaults(true);
        setEditedFaults([...faults]);
    };

    const handleEditPartsStart = () => {
        setEditParts(true);
        setEditedParts([...deviceParts]);
    };

    const handleEditEmailsStart = () => {
        setEditEmails(true);
        setEditedEmails([...emails]);
    };

    const handleDepartmentIdChange = (accessid, value) => {
        setEditedPageAccess(prevState => {
            const updatedPageAccesses = [...prevState];
            const index = updatedPageAccesses.findIndex(p => p.accessid === accessid);
            if (index !== -1 && value >= 0) {
                updatedPageAccesses[index] = {
                    ...updatedPageAccesses[index],
                    departmentid: Number(value)
                };
            }
            return updatedPageAccesses;
        });
    };

    const handleCategoryChange = (deviceid, value) => {
        setEditedCategories(prevState => {
            const updatedCategories = [...prevState];
            const index = updatedCategories.findIndex(c => c.deviceid === deviceid);
            if (index !== -1 && value >= 0) {
                updatedCategories[index] = {
                    ...updatedCategories[index],
                    devicecategory: Number(value)
                };
            }
            return updatedCategories;
        });
    };

    const handleFaultChange = (faultid, value) => {
        setEditedFaults(prevState => {
            const updatedFaults = prevState.map(fault => {
                if (fault.faultid === faultid) {
                    return { ...fault, fault: value };
                }
                return fault;
            });
            return updatedFaults;
        });
    };

    const handlePartChange = (partid, value) => {
        setEditedParts(prevState => {
            const updatedParts = prevState.map(part => {
                if (part.partid === partid) {
                    return { ...part, part: value };
                }
                return part;
            });
            return updatedParts;
        });
    };

    const handleEmailChange = (emailid, value) => {
        setEditedEmails(prevState => {
            const updatedEmails = prevState.map(email => {
                if (email.emailid === emailid) {
                    return { ...email, email: value };
                }
                return email;
            });
            return updatedEmails;
        });
    };

    const handleCancelAccess = () => {
        setEditAccess(false);
        setEditedPageAccess([]);
        setNewAccessRows([])
    };

    const handleCancelCategories = () => {
        setEditCategories(false);
        setEditedCategories([]);
        setNewCategoryRows([])
    };

    const handleCancelFaults = () => {
        setEditFaults(false);
        setEditedFaults([]);
        setNewFaultRows([])
    };

    const handleCancelParts = () => {
        setEditParts(false);
        setEditedParts([]);
        setNewPartRows([])
    };

    const handleCancelEmails = () => {
        setEditEmails(false);
        setEditedEmails([]);
        setNewEmailRows([])
    };

    const handleDeleteAccessRow = (id) => {
        setDeletedAccessRows([...deletedAccessRows, id])
        setPageAccesses(pageAccesses.filter((page) => page.accessid !== id));
    };

    const handleDeleteCategoryRow = (id) => {
        setDeletedCategoryRows([...deletedCategoryRows, id])
        setDeviceCategories(deviceCategories.filter((cat) => cat.deviceid !== id));
    };

    const handleDeleteFaultRow = (id) => {
        setDeletedFaultRows([...deletedFaultRows, id])
        setFaults(faults.filter((f) => f.faultid !== id));
    };

    const handleDeletePartRow = (id) => {
        setDeletedPartRows([...deletedPartRows, id])
        setDeviceParts(deviceParts.filter((p) => p.partid !== id));
    };

    const handleDeleteEmailRow = (id) => {
        setDeletedEmailRows([...deletedEmailRows, id])
        setEmails(emails.filter((e) => e.emailid !== id));
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/protected/updateSettings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    editedPageAccess, newAccessRows, deletedAccessRows,
                    editedCategories, newCategoryRows, deletedCategoryRows,
                    editedFaults, newFaultRows, deletedFaultRows,
                    editedParts, newPartRows, deletedPartRows,
                    editedEmails, newEmailRows, deletedEmailRows
                })
            });
            if (response.ok) {
                setShouldFetch(true);

                setEditAccess(false);
                setEditCategories(false);
                setEditFaults(false);
                setEditParts(false);
                setEditEmails(false);

                setNewAccessRows([]);
                setNewCategoryRows([]);
                setNewFaultRows([]);
                setNewPartRows([]);
                setNewEmailRows([]);
            } else {
                throw new Error('Failed to update page access');
            }
        } catch (error) {
            console.error('Error updating page access:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddAccessRow = () => {
        const newRow = {
            pagename: '',
            departmentid: '',
        };
        setNewAccessRows([...newAccessRows, newRow]);
    };

    const handleAddCategoryRow = () => {
        const newRow = {
            devicecategory: '',
        };
        setNewCategoryRows([...newCategoryRows, newRow]);
    };

    const handleAddFaultRow = () => {
        const newRow = {
            fault: '',
        };
        setNewFaultRows([...newFaultRows, newRow]);
    };

    const handleAddPartRow = () => {
        const newRow = {
            part: '',
        };
        setNewPartRows([...newPartRows, newRow]);
    };

    const handleAddEmailRow = () => {
        const newRow = {
            email: '',
        };
        setNewEmailRows([...newEmailRows, newRow]);
    };

    return (
        <div className='settingsPage'>
            {loading && (
                <div className="loading-overlay">
                    <ReactLoading type="bubbles" color="black" height={50} width={50} />
                </div>
            )}
            <header className='header'>
                <h1>Settings</h1>
            </header>
            <div className='back spaceBetween'>
                <button type="button" onClick={back}>Back</button>
            </div>
            <div className='settings-grid'>

                <div className='settings-column'>
                    {pageAccesses && (
                        <div className='departmentid'>
                            <table className='departmentidTable'>
                                <thead>
                                    <tr className='head'>
                                        <th>Page Type</th>
                                        <th>Department ID Allowed</th>
                                        {editAccess && (<th>Action</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {pageAccesses.map((page) => (
                                        <tr key={page.accessid}>
                                            <td>{page.pagename}</td>
                                            {!editAccess && (<td>{page.departmentid}</td>)}
                                            {editAccess && (
                                                <td>
                                                    <input
                                                        type='number'
                                                        min={0}
                                                        max={9999}
                                                        value={editedPageAccess.find(p => p.accessid === page.accessid)?.departmentid || ''}
                                                        onChange={(e) => handleDepartmentIdChange(page.accessid, e.target.value)}
                                                    />
                                                </td>
                                            )}
                                            {editAccess && (
                                                <td>
                                                    <button onClick={() => handleDeleteAccessRow(page.accessid)}>🗑</button>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                    {editAccess && (
                                        newAccessRows.map((row, index) => (
                                            <tr key={`new-${index}`}>
                                                <td>
                                                    <select
                                                        value={row.pagename}
                                                        onChange={(e) => {
                                                            const updatedRows = [...newAccessRows];
                                                            updatedRows[index].pagename = e.target.value;
                                                            setNewAccessRows(updatedRows);
                                                        }}
                                                    >
                                                        <option value="" disabled hidden>Select</option>
                                                        <option value="registerWarranty">registerWarranty</option>
                                                        <option value="warranty">warranty</option>
                                                        <option value="newJob">newJob</option>
                                                        <option value="repairQC">repairQC</option>
                                                        <option value="inventory">inventory</option>
                                                        <option value="approval">approval</option>
                                                        <option value="accounting">accounting</option>
                                                        <option value="outlet">outlet</option>
                                                        <option value="settings">settings</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <input
                                                        type='number'
                                                        min={0}
                                                        max={9999}
                                                        value={row.departmentid}
                                                        onChange={(e) => {
                                                            const updatedRows = [...newAccessRows];
                                                            updatedRows[index].departmentid = e.target.value;
                                                            setNewAccessRows(updatedRows);
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <button onClick={() => {
                                                        const updatedRows = [...newAccessRows];
                                                        updatedRows.splice(index, 1);
                                                        setNewAccessRows(updatedRows);
                                                    }}>🗑</button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                    {editAccess && (
                                        <tr>
                                            <td colSpan="3">
                                                <button onClick={handleAddAccessRow}>Add New Row</button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <div className='departmentidButton'>
                                {!editAccess && (<button type="button" onClick={handleEditAccessStart}>Edit</button>)}
                                {editAccess && (
                                    <div>
                                        <button type="button" onClick={handleSave} style={{ marginRight: '1rem' }}>Save</button>
                                        <button type="button" onClick={handleCancelAccess}>Cancel</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className='settings-column'>
                    <table>
                        <thead>
                            <tr className='head'>
                                <th>Device Category IDs</th>
                                {editCategories && (<th>Action</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {deviceCategories && deviceCategories.map((cat) => (
                                <tr key={cat.deviceid}>
                                    {!editCategories && (<td>{cat.devicecategory}</td>)}
                                    {editCategories && (
                                        <td>
                                            <input
                                                type='number'
                                                min={0}
                                                max={9999}
                                                value={editedCategories.find(c => c.deviceid === cat.deviceid)?.devicecategory || ''}
                                                onChange={(e) => handleCategoryChange(cat.deviceid, e.target.value)}
                                            />
                                        </td>
                                    )}
                                    {editCategories && (
                                        <td>
                                            <button onClick={() => handleDeleteCategoryRow(cat.deviceid)}>🗑</button>
                                        </td>
                                    )}
                                </tr>
                            ))}{editCategories && (
                                newCategoryRows.map((row, index) => (
                                    <tr key={`new-${index}`}>
                                        <td>
                                            <input
                                                type='number'
                                                min={0}
                                                max={9999}
                                                value={row.devicecategory}
                                                onChange={(e) => {
                                                    const updatedRows = [...newCategoryRows];
                                                    updatedRows[index].devicecategory = e.target.value;
                                                    setNewCategoryRows(updatedRows);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <button onClick={() => {
                                                const updatedRows = [...newCategoryRows];
                                                updatedRows.splice(index, 1);
                                                setNewCategoryRows(updatedRows);
                                            }}>🗑</button>
                                        </td>
                                    </tr>
                                ))
                            )}
                            {editCategories && (
                                <tr>
                                    <td colSpan="3">
                                        <button onClick={handleAddCategoryRow}>Add New Row</button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div style={{ marginTop: '1rem' }}>
                        {!editCategories && (<button type="button" onClick={handleEditCategoriesStart}>Edit</button>)}
                        {editCategories && (
                            <div>
                                <button type="button" onClick={handleSave} style={{ marginRight: '1rem' }}>Save</button>
                                <button type="button" onClick={handleCancelCategories}>Cancel</button>
                            </div>
                        )}
                    </div>
                </div>

                <div className='settings-column'>
                    <table>
                        <thead>
                            <tr className='head'>
                                <th>Faults</th>
                                {editFaults && (<th>Action</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {faults && faults.map((f) => (
                                <tr key={f.faultid}>
                                    {!editFaults && (<td>{f.fault}</td>)}
                                    {editFaults && (
                                        <td>
                                            <input
                                                type='text'
                                                value={editedFaults.find(fault => fault.faultid === f.faultid)?.fault || ''}
                                                onChange={(e) => handleFaultChange(f.faultid, e.target.value)}
                                            />
                                        </td>
                                    )}
                                    {editFaults && (
                                        <td>
                                            <button onClick={() => handleDeleteFaultRow(f.faultid)}>🗑</button>
                                        </td>
                                    )}
                                </tr>
                            ))}{editFaults && (
                                newFaultRows.map((row, index) => (
                                    <tr key={`new-${index}`}>
                                        <td>
                                            <input
                                                type='text'
                                                value={row.fault}
                                                onChange={(e) => {
                                                    const updatedRows = [...newFaultRows];
                                                    updatedRows[index].fault = e.target.value;
                                                    setNewFaultRows(updatedRows);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <button onClick={() => {
                                                const updatedRows = [...newFaultRows];
                                                updatedRows.splice(index, 1);
                                                setNewFaultRows(updatedRows);
                                            }}>🗑</button>
                                        </td>
                                    </tr>
                                ))
                            )}
                            {editFaults && (
                                <tr>
                                    <td colSpan="3">
                                        <button onClick={handleAddFaultRow}>Add New Row</button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div style={{ marginTop: '1rem' }}>
                        {!editFaults && (<button type="button" onClick={handleEditFaultsStart}>Edit</button>)}
                        {editFaults && (
                            <div>
                                <button type="button" onClick={handleSave} style={{ marginRight: '1rem' }}>Save</button>
                                <button type="button" onClick={handleCancelFaults}>Cancel</button>
                            </div>
                        )}
                    </div>
                </div>

                <div className='settings-column'>
                    <table>
                        <thead>
                            <tr className='head'>
                                <th>Parts</th>
                                {editParts && (<th>Action</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {deviceParts && deviceParts.map((p) => (
                                <tr key={p.partid}>
                                    {!editParts && (<td>{p.part}</td>)}
                                    {editParts && (
                                        <td>
                                            <input
                                                type='text'
                                                value={editedParts.find(part => part.partid === p.partid)?.part || ''}
                                                onChange={(e) => handlePartChange(p.partid, e.target.value)}
                                            />
                                        </td>
                                    )}
                                    {editParts && (
                                        <td>
                                            <button onClick={() => handleDeletePartRow(p.partid)}>🗑</button>
                                        </td>
                                    )}
                                </tr>
                            ))}{editParts && (
                                newPartRows.map((row, index) => (
                                    <tr key={`new-${index}`}>
                                        <td>
                                            <input
                                                type='text'
                                                value={row.part}
                                                onChange={(e) => {
                                                    const updatedRows = [...newPartRows];
                                                    updatedRows[index].part = e.target.value;
                                                    setNewPartRows(updatedRows);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <button onClick={() => {
                                                const updatedRows = [...newPartRows];
                                                updatedRows.splice(index, 1);
                                                setNewPartRows(updatedRows);
                                            }}>🗑</button>
                                        </td>
                                    </tr>
                                ))
                            )}
                            {editParts && (
                                <tr>
                                    <td colSpan="3">
                                        <button onClick={handleAddPartRow}>Add New Row</button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div style={{ marginTop: '1rem' }}>
                        {!editParts && (<button type="button" onClick={handleEditPartsStart}>Edit</button>)}
                        {editParts && (
                            <div>
                                <button type="button" onClick={handleSave} style={{ marginRight: '1rem' }}>Save</button>
                                <button type="button" onClick={handleCancelParts}>Cancel</button>
                            </div>
                        )}
                    </div>
                </div>

                <div className='settings-column'>
                    <table>
                        <thead>
                            <tr className='head'>
                                <th>Emails</th>
                                {editEmails && (<th>Action</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {emails && emails.map((em) => (
                                <tr key={em.emailid}>
                                    {!editEmails && (<td>{em.email}</td>)}
                                    {editEmails && (
                                        <td>
                                            <input
                                                type='text'
                                                value={editedEmails.find(email => email.emailid === em.emailid)?.email || ''}
                                                onChange={(e) => handleEmailChange(em.emailid, e.target.value)}
                                            />
                                        </td>
                                    )}
                                    {editEmails && (
                                        <td>
                                            <button onClick={() => handleDeleteEmailRow(em.emailid)}>🗑</button>
                                        </td>
                                    )}
                                </tr>
                            ))}{editEmails && (
                                newEmailRows.map((row, index) => (
                                    <tr key={`new-${index}`}>
                                        <td>
                                            <input
                                                type='text'
                                                value={row.email}
                                                onChange={(e) => {
                                                    const updatedRows = [...newEmailRows];
                                                    updatedRows[index].email = e.target.value;
                                                    setNewEmailRows(updatedRows);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <button onClick={() => {
                                                const updatedRows = [...newEmailRows];
                                                updatedRows.splice(index, 1);
                                                setNewEmailRows(updatedRows);
                                            }}>🗑</button>
                                        </td>
                                    </tr>
                                ))
                            )}
                            {editEmails && (
                                <tr>
                                    <td colSpan="3">
                                        <button onClick={handleAddEmailRow}>Add New Row</button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div style={{ marginTop: '1rem' }}>
                        {!editEmails && (<button type="button" onClick={handleEditEmailsStart}>Edit</button>)}
                        {editEmails && (
                            <div>
                                <button type="button" onClick={handleSave} style={{ marginRight: '1rem' }}>Save</button>
                                <button type="button" onClick={handleCancelEmails}>Cancel</button>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
};