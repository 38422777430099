import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from './context';

const ProtectedRoute = () => {
    const { user, loading } = useContext(UserContext);

    if (loading) {
        return null;
    }

    if (!user) {
        return <Navigate to="/" replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;