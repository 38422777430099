import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Cookies from 'js-cookie';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Login from './Login';
import ProtectedRoute from './protectedRoute';
import Dashboard from './dashboard';
import RegisterWarranty from './registerWarranty';
import Warranty from './warranty';
import WarrantyDetails from './warrantyDetails';
import NewJob from './newJob';
import RepairQC from './repairQC';
import RepairDetails from './repairDetails';
import Inventory from './inventory';
import Approval from './approval';
import ApprovalDetails from './approvalDetails';
import Accounting from './accounting';
import AccountingDetails from './accountingDetails';
import JobStatus from './jobStatus';
import JobDetails from './jobDetails';
import Outlet from './outlet';
import OutletDetails from './outletDetails';
import Settings from './settings';
import NotFound from './notFound';
import { UserProvider } from './context';
import './'

const nonce = Cookies.get('cspNonce');

const cache = createCache({
	key: 'css',
	nonce: nonce,
	prepend: true,
});

const theme = createTheme();

function App() {
	return (
		<CacheProvider value={cache}>
			<ThemeProvider theme={theme}>
				<UserProvider>
					<Router>
						<nav>
							<Routes>
								<Route path="/" element={<Login />} />
								<Route element={<ProtectedRoute />}>
									<Route path="/dashboard" element={<Dashboard />} />
									<Route path="/registerWarranty" element={<RegisterWarranty />} />
									<Route path="/warranty" element={<Warranty />} />
									<Route path="/warranty/:warrantyId" element={<WarrantyDetails />} />
									<Route path="/newJob" element={<NewJob />} />
									<Route path="/repairQC" element={<RepairQC />} />
									<Route path="/repairQC/:jobId" element={<RepairDetails />} />
									<Route path="/inventory" element={<Inventory />} />
									<Route path="/approval" element={<Approval />} />
									<Route path="/approval/:jobId" element={<ApprovalDetails />} />
									<Route path="/accounting" element={<Accounting />} />
									<Route path="/accounting/:jobId" element={<AccountingDetails />} />
									<Route path="/jobStatus" element={<JobStatus />} />
									<Route path="/jobStatus/:jobId" element={<JobDetails />} />
									<Route path="/outlet" element={<Outlet />} />
									<Route path="/outlet/:jobId" element={<OutletDetails />} />
									<Route path="/settings" element={<Settings />} />
								</Route>
								<Route path="*" element={<NotFound />} />
							</Routes>
						</nav>
					</Router>
				</UserProvider>
			</ThemeProvider>
		</CacheProvider>
	);
}

export default App;