import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from './context';
import './assets/styles/general.styles.css';
import './assets/styles/styles.css';
import './dashboard.css'
import logo from './assets/images/Hysses logo in white.png';

export default function Dashboard() {
    const { user, removeUser, accessiblePages } = useContext(UserContext);
    const navigate = useNavigate();

    const logout = () => {
        removeUser();
    };

    const hasAccess = (pageName) => {
        return accessiblePages.includes(pageName);
    };

    const navigateJobStatus = () => {
        navigate('/jobStatus');
    };
    const navigateWarranty = () => {
        navigate('/warranty');
    };
    const navigateRegisterWarranty = () => {
        navigate('/registerWarranty');
    };
    const navigateNewJob = () => {
        navigate('/newJob');
    };
    const navigateRepairQC = () => {
        navigate('/repairQC');
    };
    const navigateInventory = () => {
        navigate('/inventory');
    };
    const navigateApproval = () => {
        navigate('/approval');
    };
    const navigateAccounting = () => {
        navigate('/accounting');
    };
    const navigateOutlet = () => {
        navigate('/outlet');
    };
    const navigateSettings = () => {
        navigate('/settings');
    };

    return (
        <div className='background-color'>
            <div className='dashboard'>
                <img src={logo} alt='hysses-logo' className='logo-image-dashboard' />
                {hasAccess('registerWarranty') && <button onClick={navigateRegisterWarranty}>Register Warranty</button>}
                {hasAccess('warranty') && <button onClick={navigateWarranty}>Check Warranty</button>}
                {hasAccess('newJob') && <button onClick={navigateNewJob}>Create New Job</button>}
                <button onClick={navigateJobStatus}>Job Statuses</button>
                {hasAccess('repairQC') && (
                    <button onClick={navigateRepairQC}>Repair and Quality Check</button>
                )}
                {hasAccess('approval') && <button onClick={navigateApproval}>Customer Approvals</button>}
                {hasAccess('accounting') && <button onClick={navigateAccounting}>Accounting</button>}
                {hasAccess('outlet') && <button onClick={navigateOutlet}>Outlet Use</button>}
                {hasAccess('inventory') && (
                    <button onClick={navigateInventory}>Repair Parts Inventory</button>
                )}
                {hasAccess('settings') && (
                    <button onClick={navigateSettings}>Settings</button>
                )}
                <div className='profile'>
                    <div className='profile-info'>
                        <p className='profile-fullname'>{user ? user.name : "null"}</p>
                        <p className='profile-username'>({user ? user.departmentid : "null"})</p>
                    </div>
                    <button className='logout-button' type="button" onClick={logout} >Logout</button>
                </div>
            </div>
        </div >
    );
};